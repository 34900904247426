
export const ADD_REQUEST = 'ADD_REQUEST';

export const addRequest = (data: any) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: data,
    method: 'POST',
    url: '/requests/',
  },
  type: ADD_REQUEST,
});
