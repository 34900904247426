import { ItemType } from '../../../models';

export const ADD_ITEM_TYPE = 'ADD_ITEM_TYPE';

export const addItemType = (itemType: ItemType) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: itemType,
    method: 'POST',
    url: '/items/types/',
  },
  type: ADD_ITEM_TYPE,
});
