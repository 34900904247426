import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Form, InputNumber, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';

import { ADD_ITEM_PRICE, addItemPrice } from '../../actions';
import { UPDATE_ITEM_PRICE, updateItemPrice } from '../../actions';
import { ToolbarActions } from '../../components';
import { ItemPrice, Item } from '../../models';
import { helpers, rules } from '../../utils';

interface ItemPriceFormProps extends FormComponentProps {
  item: Item;
  itemPrice: ItemPrice | null;
  onClose: (refresh: boolean) => void;
  visible: boolean;
}

const _ItemPriceForm: React.FC<ItemPriceFormProps> = (props) => {
  const { form, item, itemPrice, onClose, visible } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading: addLoading } = useMutation({ type: ADD_ITEM_PRICE });
  const { loading: updateLoading } = useMutation({ type: UPDATE_ITEM_PRICE });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }
      const data = !itemPrice
        ? [{ ...values.itemPrice, item: item.id }]
        : [itemPrice.id, { ...values.itemPrice, item: item.id }];
      const func = !itemPrice ? addItemPrice : updateItemPrice;
      // @ts-ignore
      dispatch(func(...data))
        // @ts-ignore
        .then((response) => response && onClose(true))
        .catch(() => null);
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose(false)}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={addLoading || updateLoading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={370}
    >
      <Form>
        <Form.Item label="Precio">
          {getFieldDecorator('itemPrice.price', {
            initialValue: helpers.getOrUndefined(itemPrice, 'price'),
            rules: [rules.requiredRule()],
          })(<InputNumber min={1} style={{ width: '100%' }} />)}
        </Form.Item>

        <Form.Item label="Numero de Quincenas">
          {getFieldDecorator('itemPrice.creditWeeks', {
            initialValue: helpers.getOrUndefined(itemPrice, 'creditWeeks'),
            rules: [rules.requiredRule()],
          })(<InputNumber min={1} style={{ width: '100%' }} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const ItemPriceForm = Form.create<ItemPriceFormProps>()(_ItemPriceForm);
