import { ItemFamily } from '../../../models';

export const UPDATE_ITEM_FAMILY = 'UPDATE_ITEM_FAMILY';

export const updateItemFamily = (id: string, itemFamily: ItemFamily) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: itemFamily,
    method: 'PUT',
    url: `/items/families/${id}/`,
  },
  type: UPDATE_ITEM_FAMILY,
});
