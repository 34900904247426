import { decamelizeKeys } from 'humps';
import { stringify } from 'query-string';

export const GET_SELECT_SEARCH = 'GET_SELECT_SEARCH';

export const getSelectSearch = (
  id: string,
  url: string,
  queryParams: Record<string, any> | undefined,
) => ({
  request: {
    method: 'GET',
    url: !queryParams
      ? url
      : `${url}?${stringify(decamelizeKeys(queryParams))}`,
  },
  type: `${GET_SELECT_SEARCH}_${id.toUpperCase()}`,
});
