import React, { useState } from 'react';

import { useQuery } from '@redux-requests/react';
import { Col, Row, Table as AntTable } from 'antd';
import { ColumnProps, TableProps as AntTableProps } from 'antd/lib/table';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { TableReloadButton } from './TableReloadButton';
import { TableSearchInput } from './TableSearchInput';

import { GET_TABLE, getTable } from '../../actions';

interface TableProps<T> {
  actions?: React.ReactNode[];
  columns: ColumnProps<T>[];
  id: string;
  refresh?: number;
  tableProps?: AntTableProps<T>;
  url: string;
  withSearch?: boolean;
}

export function Table<T extends {}>(props: TableProps<T>) {
  const { actions, columns, id, tableProps, url, withSearch = true } = props;

  const dispatch = useDispatch();
  const { data, loading } = useQuery({
    defaultData: { results: [], total: 1 },
    type: `${GET_TABLE}_${id.toUpperCase()}`,
  });
  const [queryParams, setQueryParams] = useState<{
    page?: number;
    pageSize?: number;
  }>({
    page: 1,
    pageSize: 10,
  });
  const [search, setSearch] = useState<string>('');

  useDeepCompareEffect(() => {
    dispatch(getTable(id, url, { ...queryParams, search }));
  }, [id, dispatch, queryParams, search, url]);

  return (
    <Row type="flex">
      {withSearch && (
        <React.Fragment>
          <TableSearchInput
            loading={loading}
            onSearch={(value) => setSearch(value)}
          />
          <TableReloadButton
            actions={actions}
            loading={loading}
            onReload={() => dispatch(getTable(id, url, queryParams))}
          />
        </React.Fragment>
      )}
      <Col xs={{ span: 24, order: 3 }}>
        <AntTable
          bordered
          columns={columns}
          dataSource={data.results}
          loading={loading}
          onChange={(pagination, filters, sorter) =>
            setQueryParams({
              ...filters,
              page: pagination.current,
              pageSize: pagination.pageSize,
            })
          }
          pagination={{
            defaultCurrent: 1,
            defaultPageSize: 10,
            pageSizeOptions: ['10', '25', '50'],
            showSizeChanger: true,
            showTotal: (total) => `Total ${total}`,
            // @ts-ignore
            total: data.total || 1,
          }}
          rowKey="id"
          scroll={{ x: 992 }}
          size="small"
          {...tableProps}
        />
      </Col>
    </Row>
  );
}
