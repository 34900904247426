import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Col, Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';

import { ADD_REQUEST, addRequest } from '../../actions';
import { FileForm, Row, ToolbarActions } from '../../components';
import { rules } from '../../utils';

interface Props extends FormComponentProps {
  onClose: (refresh: boolean) => void;
  visible: boolean;
}

const _ModalForm: React.FC<Props> = (props) => {
  const { form, onClose, visible } = props;
  const { validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading } = useMutation({ type: ADD_REQUEST });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }

      // @ts-ignore
      dispatch(addRequest({ customer: values.customer, files: values.f.files }))
        // @ts-ignore
        .then((response) => !response.error && onClose(true))
        .catch(() => null);
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose(false)}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={loading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      title="Nuevo Pedido"
      visible={visible}
      width={570}
    >
      <Form>
        <Row>
          <Col span={24}>
            <Form.Item label="Cliente">
              {props.form.getFieldDecorator('customer', {
                rules: [rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col span={24}>
            <FileForm form={form} id="f" initialValue={[]} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const ModalForm = Form.create<Props>()(_ModalForm);
