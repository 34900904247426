import { lazy } from 'react';

import { pushToMenus, pushToRoutes } from './utils';

import { MenuRoute, RouterRoute, User } from '../models';
import { perm } from '../utils';

export default function (
  user: User,
  menus: MenuRoute[],
  routes: RouterRoute[],
) {
  if (perm.hasPermission(user, perm.CAN_ADD_ITEMS)) {
    pushToRoutes(
      routes,
      '/settings/items/add',
      lazy(() => import('../containers/Settings/Items/ItemAdd')),
      true,
    );
  }

  if (perm.hasPermission(user, perm.CAN_EDIT_ITEMS)) {
    pushToRoutes(
      routes,
      '/settings/items/:id/update',
      lazy(() => import('../containers/Settings/Items/ItemUpdate')),
      true,
    );
  }

  if (perm.hasPermission(user, perm.CAN_VIEW_ITEMS)) {
    pushToMenus(
      menus,
      { path: '/settings/items', title: 'Artículos' },
      { path: '/settings', title: 'Configuracion' },
    );

    pushToRoutes(
      routes,
      '/settings/items',
      lazy(() => import('../containers/Settings/Items/Items')),
      true,
    );
  }

  if (perm.hasPermission(user, perm.CAN_VIEW_ITEMS_PRICES)) {
    pushToRoutes(
      routes,
      '/settings/items/:id/prices',
      lazy(() => import('../containers/Settings/Items/ItemPrices')),
      true,
    );
  }
}
