import { Vendor } from '../../models';

export const UPDATE_VENDOR = 'UPDATE_VENDOR';

export const updateVendor = (id: string, vendor: Vendor) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: vendor,
    method: 'PUT',
    url: `/vendors/${id}/`,
  },
  type: UPDATE_VENDOR,
});
