import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Col, Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';

import { AddressForm, AddressFormSelects } from '../AddressForm';
import { Row } from '../Row';
import { ToolbarActions } from '../ToolbarActions';

import { ADD_ROUTE, addRoute, UPDATE_ROUTE, updateRoute } from '../../actions';
import { Route } from '../../models';
import { helpers, rules } from '../../utils';

interface RouteFormProps extends FormComponentProps {
  onClose: (refresh: boolean) => void;
  route: Route | null;
  visible: boolean;
}

const _RouteForm: React.FC<RouteFormProps> = (props) => {
  const { form, onClose, route, visible } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading: addLoading } = useMutation({ type: ADD_ROUTE });
  const { loading: updateLoading } = useMutation({ type: UPDATE_ROUTE });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }
      const { address, ...routeData } = values.route;

      const func = !route ? addRoute : updateRoute;
      const data = !route
        ? [{ ...routeData, address: [address] }]
        : [route.id, { ...routeData, address: [address] }];

      // @ts-ignore
      dispatch(func(...data)).then(
        (response: any) => response.data && onClose(true),
      );
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose(false)}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={addLoading || updateLoading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={570}
    >
      <Form>
        <Row>
          <Col xs={8}>
            <Form.Item label="Nombre">
              {getFieldDecorator('route.name', {
                initialValue: helpers.getOrUndefined(route, 'name'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item label="Codigo">
              {getFieldDecorator('route.code', {
                initialValue: helpers.getOrUndefined(route, 'code'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col span={24}>
            <AddressForm
              address={helpers.getOrNull(route, 'address[0]')}
              hideDetail
              id="route"
              form={form}
              selects={[
                AddressFormSelects.CITY,
                AddressFormSelects.COUNTRY,
                AddressFormSelects.STATE,
              ]}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const RouteForm = Form.create<RouteFormProps>()(_RouteForm);
