import { RouteSeller } from '../../../models';

export const ADD_ROUTE_SELLER = 'ADD_ROUTE_SELLER';

export const addRouteSeller = (routeSeller: RouteSeller) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: routeSeller,
    method: 'POST',
    url: '/routes/sellers',
  },
  type: ADD_ROUTE_SELLER,
});
