import { lazy } from 'react';

import { pushToMenus, pushToRoutes } from './utils';

import { MenuRoute, RouterRoute, User } from '../models';
import { perm } from '../utils';

export default function (
  user: User,
  menus: MenuRoute[],
  routes: RouterRoute[],
) {
  if (perm.hasPermission(user, perm.CAN_VIEW_WAREHOUSES)) {
    pushToMenus(menus, { path: '/warehouses', title: 'Almacen' });
    pushToRoutes(
      routes,
      '/warehouses',
      lazy(() => import('../containers/Warehouses')),
      true,
    );
  }
}
