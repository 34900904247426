import { ValidationRule } from 'antd/es/form';

export const emailRule = (): ValidationRule => ({
  type: 'email',
  message: 'Correo no valido',
});

export const maxRule = (length: number): ValidationRule => ({
  max: length,
  message: `Máximo ${length} caracteres`,
});

export const minRule = (length: number): ValidationRule => ({
  min: length,
  message: `Mínimo ${length} caracteres`,
});

export const requiredRule = (): ValidationRule => ({
  required: true,
  message: 'Requerido',
});

export const rangeRule = (min: number, max: number): ValidationRule => ({
  min,
  max,
  message: `Entre ${min} y ${max} caracteres`,
});
