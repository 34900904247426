import { Branch } from '../../models';

export const UPDATE_BRANCH = 'UPDATE_BRANCH';

export const updateBranch = (id: string, branch: Branch) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: branch,
    method: 'PUT',
    url: `/branches/${id}/`,
  },
  type: UPDATE_BRANCH,
});
