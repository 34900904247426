import React from 'react';

import { Card, Col, DatePicker, Form, Input, InputNumber } from 'antd';
import { Select as AntSelect } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { FileForm } from '../FileForm';
import { Row } from '../Row';
import { SelectSearch } from '../SelectSearch';

import { SubmitButtons } from '../SubmitButtons';
import { Maintenance, MaintenanceType, Vehicle, Vendor } from '../../models';
import { helpers, rules } from '../../utils';

interface MaintenanceFormProps extends FormComponentProps {
  loadingQuery: boolean;
  loadingMutation: boolean;
  maintenance?: Partial<Maintenance>;
  onSubmit: (data: { maintenance: Maintenance }) => void;
  title: string;
}

const _MaintenanceForm: React.FC<MaintenanceFormProps> = (props) => {
  const { form, loadingQuery, loadingMutation, maintenance, onSubmit } = props;
  const { title } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { validateFields } = form;

  const history = useHistory();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFields((errors, values) => {
      if (!errors) {
        const { maintenance } = values;

        onSubmit({
          maintenance: {
            ...maintenance,
            dateOrder: moment(maintenance.dateOrder).format('YYYY-MM-DD'),
            dateOut: moment(maintenance.dateOut).format('YYYY-MM-DD'),
          },
        });
      }
    });
  };

  return (
    <Card loading={loadingQuery} title={title}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Descripcion">
              {getFieldDecorator('maintenance.name', {
                initialValue: helpers.getOrUndefined(maintenance, 'name'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Kilometraje Actual">
              {getFieldDecorator('maintenance.kilometers', {
                initialValue: helpers.getOrUndefined(maintenance, 'kilometers'),
                rules: [rules.requiredRule()],
              })(<InputNumber style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Fecha de Entrada">
              {getFieldDecorator('maintenance.dateOrder', {
                initialValue: helpers.getOrUndefined(maintenance, 'dateOrder'),
                rules: [rules.requiredRule()],
              })(
                <DatePicker
                  onChange={() => {
                    if (getFieldValue('maintenance.dateOut')) {
                      setFieldsValue({ maintenance: { dateOut: undefined } });
                    }
                  }}
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Fecha de Salida">
              {getFieldDecorator('maintenance.dateOut', {
                initialValue: helpers.getOrUndefined(maintenance, 'dateOut'),
                rules: [rules.requiredRule()],
              })(
                <DatePicker
                  disabled={Boolean(
                    getFieldValue('maintenance.dateOrder') === undefined ||
                      getFieldValue('maintenance.dateOrder') === null,
                  )}
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <SelectSearch<Vehicle>
              fieldName="maintenance.vehicle"
              form={form}
              formItemLabel="Vehiculo"
              id="maintenance-vehicle"
              initialValue={helpers.getOrUndefined(maintenance, 'vehicle.id')}
              renderOption={(vehicle) => (
                <AntSelect.Option key={vehicle.id} value={vehicle.id}>
                  {vehicle.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/vehicles/"
            />
          </Col>

          <Col md={8} sm={12} xs={24}>
            <SelectSearch<MaintenanceType>
              fieldName="maintenance.maintenanceType"
              form={form}
              formItemLabel="Tipo de Mantenimiento"
              id="maintenance-maintenance-type"
              initialValue={helpers.getOrUndefined(
                maintenance,
                'maintenanceType.id',
              )}
              renderOption={(maintenanceType) => (
                <AntSelect.Option
                  key={maintenanceType.id}
                  value={maintenanceType.id}
                >
                  {maintenanceType.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/maintenance/types/"
            />
          </Col>

          <Col md={8} sm={12} xs={24}>
            <SelectSearch<Vendor>
              fieldName="maintenance.vendor"
              form={form}
              formItemLabel="Proveedor"
              id="maintenance-vendor"
              initialValue={helpers.getOrUndefined(maintenance, 'vendor.id')}
              renderOption={(vendor) => (
                <AntSelect.Option key={vendor.id} value={vendor.id}>
                  {vendor.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/vendors/"
            />
          </Col>

          <Col md={24} sm={24} xs={24}>
            <Form.Item label="Comentarios">
              {getFieldDecorator('maintenance.comments', {
                initialValue: helpers.getOrUndefined(maintenance, 'comments'),
                rules: [rules.requiredRule()],
              })(<Input.TextArea rows={3} />)}
            </Form.Item>
          </Col>
        </Row>

        <FileForm form={form} id="maintenance" initialValue={[]} />

        <SubmitButtons
          loading={loadingMutation}
          onCancel={() => history.push('/maintenance')}
        />
      </Form>
    </Card>
  );
};

const MaintenanceForm = Form.create<MaintenanceFormProps>()(_MaintenanceForm);

export { MaintenanceForm };
export default MaintenanceForm;
