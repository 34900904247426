import { UserGroup } from '../../../models';

export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP';

export const updateUserGroup = (id: string, group: UserGroup) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: group,
    method: 'PUT',
    url: `/users/groups/${id}/`,
  },
  type: UPDATE_USER_GROUP,
});
