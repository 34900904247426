import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';

import { ToolbarActions } from '../../components';
import { helpers, rules } from '../../utils';

interface CatalogFormProps extends FormComponentProps {
  addConfig: {
    fn: (model: any) => void;
    type: string;
  };
  customForm?: (form: WrappedFormUtils, model: any) => React.ReactElement;
  id: string;
  model: any | null;
  onClose: () => void;
  updateConfig: {
    fn: (id: string, model: any) => void;
    type: string;
  };
  visible: boolean;
}

const _CatalogForm: React.FC<CatalogFormProps> = (props) => {
  const { addConfig, customForm, form, id, model, onClose } = props;
  const { updateConfig, visible } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const { fn: add, type: addType } = addConfig;
  const { fn: update, type: updateType } = updateConfig;

  const dispatch = useDispatch();
  const { loading: addLoading } = useMutation({ type: addType });
  const { loading: updateLoading } = useMutation({ type: updateType });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }

      const data = !model ? [values[id]] : [model.id, values[id]];
      const func = !model ? add : update;
      // @ts-ignore
      dispatch(func(...data))
        // @ts-ignore
        .then(() => onClose())
        .catch(() => null);
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={onClose}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={addLoading || updateLoading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={370}
    >
      <Form>
        {customForm ? (
          customForm(form, model)
        ) : (
          <Form.Item label="Nombre">
            {getFieldDecorator(`${id}.name`, {
              initialValue: helpers.getOrUndefined(model, 'name'),
              rules: [rules.maxRule(50), rules.requiredRule()],
            })(<Input />)}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export const CatalogForm = Form.create<CatalogFormProps>()(_CatalogForm);
