import { lazy } from 'react';

import { pushToMenus, pushToRoutes } from './utils';

import { MenuRoute, RouterRoute, User } from '../models';
import { perm } from '../utils';

export default function (
  user: User,
  menus: MenuRoute[],
  routes: RouterRoute[],
) {
  if (perm.hasPermission(user, perm.CAN_VIEW_CATALOGS)) {
    pushToMenus(menus, {
      path: '/catalogs',
      subMenus: [
        {
          path: '/catalogs/items',
          title: 'Artículos',
        },
        {
          path: '/catalogs/customers',
          title: 'Clientes',
        },
        {
          path: '/catalogs/city',
          title: 'Ciudades',
        },
        {
          path: '/catalogs/colonies',
          title: 'Colonias',
        },
        {
          path: '/catalogs/maintenance',
          title: 'Mantenimientos',
        },
        {
          path: '/catalogs/users',
          title: 'Usuarios',
        },
        {
          path: '/catalogs/vehicles',
          title: 'Vehículos',
        },
        {
          path: '/catalogs/sales',
          title: 'Ventas',
        },
      ],
      title: 'Catálogos',
    });

    pushToRoutes(
      routes,
      '/catalogs/city',
      lazy(() => import('../containers/Catalogs/Cities')),
      true,
    );

    pushToRoutes(
      routes,
      '/catalogs/colonies',
      lazy(() => import('../containers/Catalogs/Colonies')),
      true,
    );
    pushToRoutes(
      routes,
      '/catalogs/customers',
      lazy(() => import('../containers/Catalogs/Customers')),
      true,
    );
    pushToRoutes(
      routes,
      '/catalogs/items',
      lazy(() => import('../containers/Catalogs/Items')),
      true,
    );
    pushToRoutes(
      routes,
      '/catalogs/maintenance',
      lazy(() => import('../containers/Catalogs/Maintenance')),
      true,
    );
    pushToRoutes(
      routes,
      '/catalogs/sales',
      lazy(() => import('../containers/Catalogs/Sales')),
      true,
    );
    pushToRoutes(
      routes,
      '/catalogs/users',
      lazy(() => import('../containers/Catalogs/Users')),
      true,
    );
    pushToRoutes(
      routes,
      '/catalogs/vehicles',
      lazy(() => import('../containers/Catalogs/Vehicles')),
      true,
    );
  }
}
