import { decamelizeKeys } from 'humps';
import { parseUrl, stringify } from 'query-string';

export const GET_TABLE = 'GET_TABLE';

export const getTable = (
  id: string,
  url: string,
  queryParams: Record<string, any> = { page: 1, pageSize: 10 },
) => ({
  request: {
    method: 'GET',
    url: (() => {
      const parsedUrl = parseUrl(url);
      return `${parsedUrl.url}?${stringify(
        decamelizeKeys({ ...parsedUrl.query, ...queryParams }),
        { skipEmptyString: true, skipNull: true },
      )}`;
    })(),
  },
  type: `GET_TABLE_${id.toUpperCase()}`,
});
