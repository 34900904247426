import React from 'react';

import { find } from 'lodash';

import { MenuRoute, RouterRoute } from '../models';

export const pushToMenus = (
  menus: MenuRoute[],
  menu: MenuRoute,
  subPath?: MenuRoute,
) => {
  if (subPath !== undefined) {
    let subMenu = find(menus, ['path', subPath.path]);
    if (!subMenu) {
      if (subPath.subMenus?.length) {
        subPath.subMenus.push(menu);
      } else {
        subPath.subMenus = [menu];
      }
      menus.push(subPath);
    } else {
      subMenu?.subMenus?.push(menu);
    }
  } else {
    menus.push(menu);
  }
};

export const pushToRoutes = (
  routes: RouterRoute[],
  path: string,
  component: React.ComponentType<any>,
  exact: boolean = false,
) => {
  routes.push({ key: path, exact: exact, component: component, path: path });
};

export const orderMenus = () => {};
