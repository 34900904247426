import React from 'react';

import { Card, Col, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';

import { AddressForm, Row, SubmitButtons } from '../../components';
import { Branch } from '../../models';
import { helpers, rules } from '../../utils';

interface BranchFormProps extends FormComponentProps {
  branch?: Partial<Branch>;
  loadingQuery: boolean;
  loadingMutation: boolean;
  onSubmit: (data: { branch: Branch }) => void;
  title: string;
}

const _BranchForm: React.FC<BranchFormProps> = (props) => {
  const { branch, form, loadingQuery, loadingMutation, onSubmit } = props;
  const { title } = props;
  const { getFieldDecorator, validateFields } = form;

  const history = useHistory();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFields((errors, values) => {
      if (!errors) {
        const data = {
          branch: {
            ...values.branch,
            address: [values.branch.address],
          },
        };
        if (branch && branch.address && branch.address.length) {
          data['branch']['address'][0]['id'] = branch.address[0].id;
        }
        onSubmit(data);
      }
    });
  };

  return (
    <Card loading={loadingQuery} title={title}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Nombre">
              {getFieldDecorator('branch.name', {
                initialValue: helpers.getOrUndefined(branch, 'name'),
                rules: [rules.maxRule(50)],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <AddressForm
          address={(branch?.address && branch.address[0]) || null}
          id="branch"
          form={form}
        />

        <SubmitButtons
          loading={loadingMutation}
          onCancel={() => history.push('/settings/branches')}
        />
      </Form>
    </Card>
  );
};

const BranchForm = Form.create<BranchFormProps>()(_BranchForm);

export { BranchForm };
export default BranchForm;
