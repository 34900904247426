import { PaymentPending } from '../../models';

export const DELETE_PAYMENT_PENDING = 'DELETE_PAYMENT_PENDING';

export const deletePaymentPending = (data: PaymentPending) => ({
  meta: {
    asPromise: true,
  },
  request: {
    method: 'DELETE',
    url: `/payments/pending/${data.id}/`,
  },
  type: DELETE_PAYMENT_PENDING,
});
