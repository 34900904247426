import { UserMaritalStatus } from '../../../models';

export const UPDATE_USER_MARITAL_STATUS = 'UPDATE_USER_MARITAL_STATUS';

export const updateUserMaritalStatus = (
  id: string,
  maritalStatus: UserMaritalStatus,
) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: maritalStatus,
    method: 'PUT',
    url: `/users/marital-status/${id}/`,
  },
  type: UPDATE_USER_MARITAL_STATUS,
});
