import React from 'react';

import { Card, Col, DatePicker, Divider, Form, Input } from 'antd';
import { Select as AntSelect } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { Addresses, FileForm, Row, Select } from '../../components';
import { SubmitButtons } from '../../components';
import { Branch, User, UserGender, UserGroup, UserJob } from '../../models';
import { UserMaritalStatus } from '../../models';
import { helpers, rules } from '../../utils';

interface UserFormProps extends FormComponentProps {
  loadingQuery: boolean;
  loadingMutation: boolean;
  onSubmit: (data: { user: User }) => void;
  title: string;
  user?: Partial<User>;
}

export const _UserForm: React.FC<UserFormProps> = (props) => {
  const { form, loadingQuery, loadingMutation, onSubmit, user, title } = props;
  const { getFieldDecorator, validateFields } = form;

  const history = useHistory();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFields((errors, values) => {
      if (!errors) {
        onSubmit({
          user: {
            ...values.user,
            birthday: moment(values.birthday).format('YYYY-MM-DD'),
          },
        });
      }
    });
  };

  return (
    <Card loading={loadingQuery} title={title}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Divider orientation="left">Informacion de Usuario</Divider>
          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Nombre(s)">
              {getFieldDecorator('user.firstName', {
                initialValue: helpers.getOrUndefined(user, 'firstName'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Apellidos">
              {getFieldDecorator('user.lastName', {
                initialValue: helpers.getOrUndefined(user, 'lastName'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Divider orientation="left">Información Adicional</Divider>

          <Col md={6} sm={12} xs={24}>
            <Form.Item label="Correo">
              {getFieldDecorator('user.email', {
                initialValue: helpers.getOrUndefined(user, 'email'),
                rules: [rules.emailRule(), rules.maxRule(50)],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Select<Branch>
              fieldName="user.branch"
              form={form}
              formItemLabel="Sucursal"
              id="user-branch"
              initialValue={helpers.getOrUndefined(user, 'branch.id')}
              renderOption={(branch) => (
                <AntSelect.Option key={branch.id} value={branch.id}>
                  {branch.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/branches/"
            />
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Select<UserJob>
              fieldName="user.job"
              form={form}
              formItemLabel="Puesto"
              id="user-job"
              initialValue={helpers.getOrUndefined(user, 'job.id')}
              renderOption={(job) => (
                <AntSelect.Option key={job.id} value={job.id}>
                  {job.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/users/jobs/"
            />
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Select<UserGroup>
              fieldName="user.group"
              form={form}
              formItemLabel="Grupo"
              id="user-group"
              initialValue={helpers.getOrUndefined(user, 'group.id')}
              renderOption={(group) => (
                <AntSelect.Option key={group.id} value={group.id}>
                  {group.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/users/groups/"
            />
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Select<UserGender>
              fieldName="user.gender"
              form={form}
              formItemLabel="Genero"
              id="user-gender"
              initialValue={helpers.getOrUndefined(user, 'gender.id')}
              renderOption={(gender) => (
                <AntSelect.Option key={gender.id} value={gender.id}>
                  {gender.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/users/genders/"
            />
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Select<UserMaritalStatus>
              fieldName="user.maritalStatus"
              form={form}
              formItemLabel="Estado Civil"
              id="user-marital-status"
              initialValue={helpers.getOrUndefined(user, 'maritalStatus.id')}
              renderOption={(maritalStatus) => (
                <AntSelect.Option
                  key={maritalStatus.id}
                  value={maritalStatus.id}
                >
                  {maritalStatus.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/users/marital-status/"
            />
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Form.Item label="Fecha de Nacimiento">
              {getFieldDecorator('user.birthday', {
                initialValue:
                  helpers.getOrUndefined(user, 'birthday') === undefined
                    ? moment().subtract(3, 'years')
                    : moment(helpers.getOrUndefined(user, 'birthday')),
                rules: [rules.requiredRule()],
              })(
                <DatePicker
                  disabledDate={(current) =>
                    current ? current > moment().subtract(3, 'years') : false
                  }
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Form.Item label="Teléfono">
              {getFieldDecorator('user.phoneNumber', {
                initialValue: helpers.getOrUndefined(user, 'phoneNumber'),
                rules: [rules.maxRule(20), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        {!user?.id && (
          <Row>
            <Divider orientation="left">
              Informacion del Inicio de Sesion
            </Divider>

            <Col md={8} sm={12} xs={24}>
              <Form.Item label="Nombre Usuario">
                {getFieldDecorator('user.username', {
                  initialValue: helpers.getOrUndefined(user, 'username'),
                  rules: [rules.maxRule(50), rules.requiredRule()],
                })(<Input />)}
              </Form.Item>
            </Col>

            <Col md={8} sm={12} xs={24}>
              <Form.Item label="Contraseña">
                {getFieldDecorator('user.password', {
                  rules: [rules.requiredRule()],
                })(<Input.Password />)}
              </Form.Item>
            </Col>
          </Row>
        )}

        <FileForm
          form={form}
          id="user"
          initialValue={helpers.getOrUndefined(user, 'files')}
        />

        {user && user.id && (
          <Addresses id="users" url={`/users/${user.id}/addresses/`} />
        )}


        <SubmitButtons
          loading={loadingMutation}
          onCancel={() => history.push('/settings/users')}
        />
      </Form>
    </Card>
  );
};

export const UserForm = Form.create<UserFormProps>()(_UserForm);
