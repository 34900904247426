import { ItemPrice } from '../../../models';

export const ADD_ITEM_PRICE = 'ADD_ITEM_PRICE';

export const addItemPrice = (itemPrice: ItemPrice) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: itemPrice,
    method: 'POST',
    url: `/items/prices/`,
  },
  type: ADD_ITEM_PRICE,
});
