import { Address } from '../../models';

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';

export const updateAddress = (url: string, address: Address) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: address,
    method: 'PUT',
    url: url,
  },
  type: UPDATE_ADDRESS,
});
