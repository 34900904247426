import { SaleType } from '../../../models';

export const UPDATE_SALE_TYPE = 'UPDATE_SALE_TYPE';

export const updateSaleType = (id: string, saleType: SaleType) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: saleType,
    method: 'PUT',
    url: `/sales/types/${id}/`,
  },
  type: UPDATE_SALE_TYPE,
});
