import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';

import { axios } from '../utils';

export default function configureStore() {
  const { requestsReducer, requestsMiddleware } = handleRequests({
    driver: createDriver(axios),
  });
  const reducers = combineReducers({ requests: requestsReducer });
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  return createStore(
    reducers,
    composeEnhancers(applyMiddleware(...requestsMiddleware)),
  );
}
