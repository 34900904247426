import JwtDecode from 'jwt-decode';

import { JwtToken } from '../models';

const JWT = 'access';

export function setToken(token: string) {
  sessionStorage.setItem(JWT, token);
}

export function getToken(): string | null {
  return sessionStorage.getItem(JWT);
}

export function getValidToken(): JwtToken | null | undefined {
  const token = getToken();
  if (isTokenExpired(token) || !token) {
    return null;
  }
  return { access: token };
}

export function clearToken() {
  sessionStorage.removeItem(JWT);
}

export function getTokenExpirationDate(encodedToken: string) {
  try {
    const token: any = JwtDecode(encodedToken);
    if (!token.exp) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);
    return date;
  } catch (e) {
    return null;
  }
}

export function isTokenExpired(token = getToken()) {
  if (!token) {
    return true;
  }

  const expirationDate = getTokenExpirationDate(token);
  return expirationDate ? expirationDate < new Date() : false;
}
