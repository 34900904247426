export const ASSOCIATE_SALE = 'ASSOCIATE_SALE';

export const associateSale = (saleId: string, data: any) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: data,
    method: 'POST',
    url: `/sales/${saleId}/associate/`,
  },
  type: ASSOCIATE_SALE,
});
