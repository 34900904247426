export const CANCEL_SALE_REQUEST = 'CANCEL_SALE_REQUEST';

export const cancelSaleRequest = (saleId: string, data: any) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: data,
    method: 'POST',
    url: `/sales/${saleId}/cancel/request/`,
  },
  type: CANCEL_SALE_REQUEST,
});
