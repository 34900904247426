import React, { useEffect } from 'react';

import { useQuery } from '@redux-requests/react';
import { Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { GET_ME, getMe } from '../actions';
import { PrivateRoute } from '../components';
import { UserContext } from '../context';
import { RouterRoute } from '../models';
import { getConfig } from '../routes';

export const Root = () => {
  const dispatch = useDispatch();
  const { data: me, loading, pristine } = useQuery({ type: GET_ME });
  const routes: RouterRoute[] = getConfig(me).routes;

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  if (loading || pristine) {
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <UserContext.Provider value={me}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>

        {routes.map((route) => (
          <PrivateRoute
            route={route}
            exact={route.exact}
            key={route.key}
            path={route.path}
          />
        ))}

        <Route exact path="*">
          <Redirect to={me ? '/dashboard' : '/login'} />
        </Route>
      </Switch>
    </UserContext.Provider>
  );
};
