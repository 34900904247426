import React from 'react';

import { Col, Divider, Form, Input, Select as AntSelect } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { Row } from '../Row';
import { SelectSearch } from '../SelectSearch';

import { Address, City, Colony, Country, State } from '../../models';
import { helpers, rules } from '../../utils';

interface AddressFormProps extends FormComponentProps {
  address: Address | null;
  hideDetail?: boolean;
  id: string;
  selects?: AddressFormSelects[];
}

export enum AddressFormSelects {
  'CITY',
  'COLONY',
  'COUNTRY',
  'STATE',
}

export const AddressForm: React.FC<AddressFormProps> = (props) => {
  const { address, form, hideDetail, id, selects } = props;
  const { getFieldDecorator, getFieldValue } = form;

  const calculateSelectColumns: number = (() => {
    if (selects?.length === 4) {
      return 6;
    }
    if (selects?.length === 3) {
      return 8;
    }
    if (selects?.length === 2) {
      return 12;
    }
    return 24;
  })();

  return (
    <Row>
      <Col span={24}>
        <Divider orientation="left">Dirección</Divider>
      </Col>

      {address?.id &&
        getFieldDecorator(`${id}.address.id`, {
          initialValue: helpers.getOrUndefined(address, 'id'),
        })(<Input style={{ display: 'none' }} />)}

      {!hideDetail && (
        <React.Fragment>
          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Calle">
              {getFieldDecorator(`${id}.address.street`, {
                initialValue: helpers.getOrUndefined(address, 'street'),
                rules: [rules.rangeRule(1, 40), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={4} sm={6} xs={12}>
            <Form.Item label="No. Exterior">
              {getFieldDecorator(`${id}.address.streetNumber`, {
                initialValue: helpers.getOrUndefined(address, 'streetNumber'),
                rules: [rules.rangeRule(1, 10), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={4} sm={6} xs={12}>
            <Form.Item label="No. Interior">
              {getFieldDecorator(`${id}.address.apartmentNumber`, {
                initialValue: helpers.getOrUndefined(
                  address,
                  'apartmentNumber',
                ),
                rules: [rules.rangeRule(1, 10)],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={6} sm={12} xs={12}>
            <Form.Item label="Código Postal">
              {getFieldDecorator(`${id}.address.postcode`, {
                initialValue: helpers.getOrUndefined(address, 'postcode'),
                rules: [rules.rangeRule(4, 5)],
              })(<Input />)}
            </Form.Item>
          </Col>
        </React.Fragment>
      )}

      {selects?.includes(AddressFormSelects.COUNTRY) && (
        <Col md={calculateSelectColumns} sm={12} xs={24}>
          <SelectSearch<Country>
            fieldName={`${id}.address.country`}
            form={form}
            formItemLabel="Pais"
            id="address-country"
            initialValue={helpers.getOrUndefined(address, 'country.id')}
            renderOption={(country) => (
              <AntSelect.Option key={country.id} value={country.id}>
                {country.name}
              </AntSelect.Option>
            )}
            rules={[rules.requiredRule()]}
            url="/countries/"
          />
        </Col>
      )}

      {selects?.includes(AddressFormSelects.COUNTRY) &&
        selects?.includes(AddressFormSelects.STATE) && (
          <Col md={calculateSelectColumns} sm={12} xs={24}>
            <SelectSearch<State>
              fieldName={`${id}.address.state`}
              form={form}
              formItemLabel="Estado"
              id="address-state"
              initialValue={helpers.getOrUndefined(address, 'state.id')}
              queryParams={{
                countryId:
                  address !== null
                    ? helpers.getOrUndefined(address, 'state.id')
                    : getFieldValue(`${id}.address.country`),
              }}
              renderOption={(state) => (
                <AntSelect.Option key={state.id} value={state.id}>
                  {state.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              selectProps={{
                disabled:
                  address !== null
                    ? false
                    : !Boolean(getFieldValue(`${id}.address.country`)),
              }}
              url="/states/"
            />
          </Col>
        )}

      {selects?.includes(AddressFormSelects.COUNTRY) &&
        selects?.includes(AddressFormSelects.STATE) &&
        selects?.includes(AddressFormSelects.CITY) && (
          <Col md={calculateSelectColumns} sm={12} xs={24}>
            <SelectSearch<City>
              fieldName={`${id}.address.city`}
              form={form}
              formItemLabel="Ciudad"
              id="address-city"
              initialValue={helpers.getOrUndefined(address, 'city.id')}
              queryParams={{
                stateId:
                  address !== null
                    ? helpers.getOrUndefined(address, 'state.id')
                    : getFieldValue(`${id}.address.state`),
              }}
              renderOption={(city) => (
                <AntSelect.Option key={city.id} value={city.id}>
                  {city.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              selectProps={{
                disabled:
                  address !== null
                    ? false
                    : !Boolean(getFieldValue(`${id}.address.state`)),
              }}
              url="/cities/"
            />
          </Col>
        )}

      {selects?.includes(AddressFormSelects.COUNTRY) &&
        selects?.includes(AddressFormSelects.STATE) &&
        selects?.includes(AddressFormSelects.CITY) &&
        selects?.includes(AddressFormSelects.COLONY) && (
          <Col md={calculateSelectColumns} sm={12} xs={24}>
            <SelectSearch<Colony>
              fieldName={`${id}.address.colony`}
              form={form}
              formItemLabel="Colonia"
              id="address-colony"
              initialValue={helpers.getOrUndefined(address, 'colony.id')}
              queryParams={{
                cityId:
                  address !== null
                    ? helpers.getOrUndefined(address, 'state.id')
                    : getFieldValue(`${id}.address.city`),
              }}
              renderOption={(colony) => (
                <AntSelect.Option key={colony.id} value={colony.id}>
                  {colony.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              selectProps={{
                disabled:
                  address !== null
                    ? false
                    : !Boolean(getFieldValue(`${id}.address.city`)),
              }}
              url="/colonies/"
            />
          </Col>
        )}
    </Row>
  );
};

AddressForm.defaultProps = {
  hideDetail: false,
  selects: [
    AddressFormSelects.CITY,
    AddressFormSelects.COLONY,
    AddressFormSelects.COUNTRY,
    AddressFormSelects.STATE,
  ],
};
