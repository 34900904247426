import { lazy } from 'react';

import branches from './branches';
import catalogs from './catalogs';
import clientBalance from "./clientBalance";
import customers from './customers';
import items from './items';
import maintenance from './maintenance';
import payments from './payments';
import purchases from './purchases';
import requests from './requests';
import reports from "./reports";
import sales from './sales';
import sellers from './sellers';
import users from './users';
import vehicles from './vehicles';
import vendors from './vendors';
import warehouses from './warehouses';

import { MenuRoute, RouterRoute, User } from '../models';

export const getConfig = (user?: User) => {
  if (!user) {
    return {
      menus: [],
      routes: [
        {
          component: lazy(() => import('../containers/Login')),
          exact: true,
          key: '/login',
          path: '/login',
        },
      ],
    };
  }

  const menus: MenuRoute[] = [];
  const routes: RouterRoute[] = [
    {
      key: '/dashboard',
      component: lazy(() => import('../containers/Dashboard')),
      path: '/dashboard',
    },
  ];

  if (user?.group?.isSeller) {
    sellers(user, menus, routes);
  } else {
    clientBalance(user, menus, routes)
    payments(user, menus, routes);
    reports(user, menus, routes);
    sales(user, menus, routes);
    requests(user, menus, routes);
    purchases(user, menus, routes);
    warehouses(user, menus, routes);
    customers(user, menus, routes);
    maintenance(user, menus, routes);
    catalogs(user, menus, routes);
    items(user, menus, routes);
    vendors(user, menus, routes);
    branches(user, menus, routes);
    users(user, menus, routes);
    vehicles(user, menus, routes);
  }

  return { menus, routes };
};
