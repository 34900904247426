import React from 'react';

import { useQuery } from '@redux-requests/react';
import { Col, Form, Row, Select as AntSelect } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { LabeledValue, SelectProps as AntSelectProps } from 'antd/lib/select';
import { SelectValue } from 'antd/lib/select';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { GET_SELECT, getSelect } from '../../actions';

interface SelectProps<T> extends FormComponentProps {
  extraDataSource?: T[];
  fieldName: string;
  formItemLabel?: string | React.ReactNode;
  initialValue?: string | Record<string, any> | LabeledValue;
  id: string;
  onChange?: (value: SelectValue) => void;
  queryParams?: Record<string, any>;
  renderOption: (item: T) => React.ReactNode;
  rules?: Record<string, any>[];
  selectProps?: AntSelectProps;
  url: string;
}

export function Select<T extends {}>(props: SelectProps<T>) {
  const { extraDataSource, fieldName, form, formItemLabel } = props;
  const { initialValue, id, onChange, queryParams, renderOption } = props;
  const { rules, selectProps, url } = props;

  const { getFieldDecorator } = form;

  const renderSelect = (
    data: T[] = [],
    localSelectProps: AntSelectProps = {},
  ) => (
    <AntSelect
      onChange={(value: any) => onChange && onChange(value)}
      style={{ width: '100%' }}
      {...localSelectProps}
      {...selectProps}
    >
      {data.length && data.map((item: T) => renderOption(item))}
    </AntSelect>
  );

  const dispatch = useDispatch();
  const { data, loading } = useQuery({
    defaultData: [],
    type: `${GET_SELECT}_${id.toUpperCase()}`,
  });

  useDeepCompareEffect(() => {
    if (!selectProps?.disabled) {
      dispatch(getSelect(id, url, queryParams));
    }
  }, [dispatch, id, queryParams, selectProps, url]);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item label={formItemLabel}>
          {getFieldDecorator(fieldName, {
            initialValue,
            rules,
          })(
            renderSelect(
              extraDataSource ? [...data, ...extraDataSource] : data,
              { loading },
            ),
          )}
        </Form.Item>
      </Col>
    </Row>
  );
}
