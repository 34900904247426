import React, { useState } from 'react';

import { Layout as AntLayout } from 'antd';
import { useLocation } from 'react-use';

import './Layout.css';

import { Sider } from '../Sider';
import { Header } from '../Header';

interface LayoutProps {
  children: React.ReactElement;
}

export const Layout: React.FC<LayoutProps> = (props) => {
  const { children } = props;

  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  if (location.pathname === '/login') {
    return children;
  }

  return (
    <AntLayout className="main-container">
      <Sider collapsed={collapsed} />
      <AntLayout>
        <Header
          collapsed={collapsed}
          onCollapsed={() => setCollapsed(!collapsed)}
        />
        <AntLayout.Content className="content">{children}</AntLayout.Content>
      </AntLayout>
    </AntLayout>
  );
};
