export const AUTHORIZE_REQUEST = 'AUTHORIZE_REQUEST';

export const authorizeRequest = (id: string) => ({
  meta: {
    asPromise: true,
  },
  request: {
    method: 'POST',
    url: `/requests/${id}/authorize/`,
  },
  type: AUTHORIZE_REQUEST,
});
