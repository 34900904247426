import { Customer } from '../../models';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';

export const updateCustomer = (id: string, customer: Customer) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: customer,
    method: 'PUT',
    url: `/customers/${id}/`,
  },
  type: UPDATE_CUSTOMER,
});
