import humps from 'humps';
import { serialize } from 'object-to-formdata';

export const ADD_PAYMENT_EXCEL = 'ADD_PAYMENT_EXCEL';

export const addPaymentExcel = (data: any) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: serialize(humps.decamelizeKeys(data), { indices: true }),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    url: '/payments/excel/',
  },
  type: ADD_PAYMENT_EXCEL,
});
