import humps from 'humps';
import { serialize } from 'object-to-formdata';

export const ADD_ITEM_PRICE_EXCEL = 'ADD_ITEM_PRICE_EXCEL';

export const addItemPriceExcel = (data: any) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: serialize(humps.decamelizeKeys(data), { indices: true }),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    url: '/items/prices/excel/',
  },
  type: ADD_ITEM_PRICE_EXCEL,
});
