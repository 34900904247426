import React, { useState } from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Form, Modal, Upload } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { UploadFile } from 'antd/lib/upload/interface';
import { useDispatch } from 'react-redux';

import { ADD_ITEM_PRICE_EXCEL, addItemPriceExcel } from '../../actions';
import { ToolbarActions } from '../../components';
import { rules } from '../../utils';

interface Props extends FormComponentProps {
  onClose: () => void;
  visible: boolean;
}

const _ItemPriceExcelForm: React.FC<Props> = (props) => {
  const { form, onClose, visible } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading } = useMutation({ type: ADD_ITEM_PRICE_EXCEL });
  const [files, setFiles] = useState<UploadFile | null>(null);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors) => {
      if (errors) {
        return;
      }

      // @ts-ignore
      dispatch(addItemPriceExcel({ file: files }))
        // @ts-ignore
        .then((response) => response.data && onClose())
        .catch(() => null);
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={onClose}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={loading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      title="Actualizar Precios de Articulos"
      visible={visible}
      width={370}
    >
      <Form>
        <Form.Item label="Excel">
          {getFieldDecorator('files', {
            rules: [rules.requiredRule()],
          })(
            <Upload
              accept=".xlsx, .xls"
              beforeUpload={(file) => {
                setFiles(file);
                return false;
              }}
              fileList={files ? [files] : []}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: false,
                showRemoveIcon: false,
              }}
            >
              <Button icon="plus">Agregar</Button>
            </Upload>,
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const ItemPriceExcelForm = Form.create<Props>()(_ItemPriceExcelForm);
