import humps from 'humps';
import { serialize } from 'object-to-formdata';

import { Maintenance } from '../../models';

export const ADD_MAINTENANCE = 'ADD_MAINTENANCE';

export const addMaintenance = (maintenance: Maintenance) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: serialize(humps.decamelizeKeys(maintenance), { indices: true }),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    url: '/maintenance/',
  },
  type: ADD_MAINTENANCE,
});
