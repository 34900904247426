import { City } from '../../models';

export const ADD_CITY = 'ADD_CITY';

export const addCity = (city: City) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: city,
    method: 'POST',
    url: '/cities/',
  },
  type: ADD_CITY,
});
