import { lazy } from 'react';

import { pushToMenus, pushToRoutes } from './utils';

import { MenuRoute, RouterRoute, User } from '../models';
import { perm } from '../utils';

export default function (
  user: User,
  menus: MenuRoute[],
  routes: RouterRoute[],
) {
  if (perm.hasPermission(user, perm.CAN_ADD_VENDORS)) {
    pushToRoutes(
      routes,
      '/settings/vendors/add',
      lazy(() => import('../containers/Settings/Vendors/VendorAdd')),
      true,
    );
  }

  if (perm.hasPermission(user, perm.CAN_EDIT_VENDORS)) {
    pushToRoutes(
      routes,
      '/settings/vendors/:id/update',
      lazy(() => import('../containers/Settings/Vendors/VendorUpdate')),
      true,
    );
  }

  if (perm.hasPermission(user, perm.CAN_VIEW_VENDORS)) {
    pushToMenus(
      menus,
      { path: '/settings/vendors', title: 'Proveedores' },
      { path: '/settings', title: 'Configuracion' },
    );
    pushToRoutes(
      routes,
      '/settings/vendors',
      lazy(() => import('../containers/Settings/Vendors/Vendors')),
      true,
    );
  }
}
