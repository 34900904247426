export const CLOSE_SALE = 'CLOSE_SALE';

export const closeSale = (type: string, saleId: string, data: any) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: data,
    method: 'POST',
    url: `/sales/${saleId}/${type}/`,
  },
  type: CLOSE_SALE,
});
