import { decamelizeKeys } from 'humps';
import { stringify } from 'query-string';

export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';

export const downloadReport = (queryParams: Record<string, any>) => ({
  meta: {
    asPromise: true,
  },
  request: {
    method: 'GET',
    url: `/reports/?${stringify(decamelizeKeys(queryParams))}`,
  },
  type: DOWNLOAD_REPORT,
});
