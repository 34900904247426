import { RouteSeller } from '../../../models';

export const UPDATE_ROUTE_SELLER = 'UPDATE_ROUTE_SELLER';

export const updateRouteSeller = (id: string, route: RouteSeller) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: route,
    method: 'PUT',
    url: `/routes/sellers/${id}/`,
  },
  type: UPDATE_ROUTE_SELLER,
});
