import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Col, Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';

import { CANCEL_SALE_REQUEST, cancelSaleRequest } from '../../actions';
import { FileForm, Row, ToolbarActions } from '../../components';
import { Sale } from '../../models';
import { rules } from '../../utils';

interface Props extends FormComponentProps {
  onClose: (refresh: boolean) => void;
  sale: Sale | null;
  visible: boolean;
}

const _SaleCancelRequestForm: React.FC<Props> = (props) => {
  const { form, onClose, sale, visible } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading } = useMutation({ type: CANCEL_SALE_REQUEST });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (!errors && sale) {
        dispatch(
          cancelSaleRequest(sale.id, {
            canceledComments: values.sale.canceledComments,
            canceledFiles: values.sale.files,
          }),
        )
          // @ts-ignore
          .then((response) => !response.error && onClose(true))
          .catch(() => null);
      }
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose(false)}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={loading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={570}
    >
      <Form>
        <Row>
          <Col span={24}>
            <Form.Item label="Motivo">
              {getFieldDecorator('sale.canceledComments', {
                rules: [rules.requiredRule()],
              })(<Input.TextArea rows={3} />)}
            </Form.Item>
          </Col>

          <Col span={24}>
            <FileForm form={form} id="sale" initialValue={[]} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const SaleCancelRequestForm = Form.create<Props>()(
  _SaleCancelRequestForm,
);
