import { get } from 'lodash';

export const getOrNull = (obj: any, ...args: string[]): any | null => {
  for (let i = 0; i < args.length; i++) {
    let value = get(obj, `${args[i]}`, false);
    if (value) {
      return value;
    }
  }
  return null;
};

export const getOrUndefined = (obj: any, ...args: string[]): any | null => {
  for (let i = 0; i < args.length; i++) {
    let value = get(obj, `${args[i]}`, false);
    if (value) {
      return value;
    }
  }
  return undefined;
};
