import { Customer } from '../../models';

export const ADD_CUSTOMER = 'ADD_CUSTOMER';

export const addCustomer = (customer: Customer) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: customer,
    method: 'POST',
    url: '/customers/',
  },
  type: ADD_CUSTOMER,
});
