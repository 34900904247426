import React from 'react';

import { Icon, Layout } from 'antd';

import './Header.css';

import { DropDownActions } from '../DropDownActions';

import { jwt } from '../../utils';

interface Props {
  collapsed: boolean;
  onCollapsed: () => void;
}

export const Header: React.FC<Props> = (props) => {
  const { collapsed, onCollapsed } = props;

  return (
    <Layout.Header
      className="header"
      style={{ background: '#fff', padding: 0 }}
    >
      <Icon
        className="trigger"
        type={collapsed ? 'menu-unfold' : 'menu-fold'}
        onClick={onCollapsed}
      />
      <DropDownActions
        menuOptions={[{ key: 'logout', name: 'Cerrar sesión' }]}
        onMenuClick={() => {
          jwt.clearToken();
          window.location.reload();
        }}
      >
        <Icon className="trigger" type="user" />
      </DropDownActions>
    </Layout.Header>
  );
};
