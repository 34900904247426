export const DELETE_INSTALLMENT = 'DELETE_INSTALLMENT';

export const deleteInstallment = (id: string, paymentId: string) => ({
  meta: {
    asPromise: true,
  },
  request: {
    method: 'DELETE',
    url: `/payments/${paymentId}/installments/?installment_id=${id}`,
  },
  type: DELETE_INSTALLMENT,
});
