import React, { useState } from 'react';

import { Card, Col, DatePicker, Divider, Form, Input, InputNumber } from 'antd';
import { Select as AntSelect, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { FileForm, ItemPriceFortnightInput, Row } from '../../components';
import { Select, SelectSearch, SubmitButtons } from '../../components';
import { Customer, Item, Sale } from '../../models';
import { SaleType, User } from '../../models';
import { helpers, rules } from '../../utils';

interface SaleFormProps extends FormComponentProps {
  loadingQuery: boolean;
  loadingMutation: boolean;
  onSubmit: (data: { sale: Sale }) => void;
  title: string;
  sale?: Partial<Sale>;
}

const _SaleForm: React.FC<SaleFormProps> = (props) => {
  const { form, loadingQuery, loadingMutation, onSubmit, sale } = props;
  const { title } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { validateFields } = form;

  const history = useHistory();
  const [timeLimit, setTimeLimit] = useState(0);
  const [debouncedTimeLimit, setDebouncedTimeLimit] = useState(0);

  useDebounce(
    () => {
      setDebouncedTimeLimit(timeLimit);
    },
    1000,
    [timeLimit],
  );

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFields((errors, values) => {
      if (!errors) {
        const { itemDetail, ...sale } = values.sale;
        onSubmit({
          sale: {
            ...sale,
            items: Object.values(itemDetail).map((itemDetail) => ({
              // @ts-ignore
              ...itemDetail,
            })),
            dateInvoice: moment(sale.dateInvoice).format('YYYY-MM-DD'),
          },
        });
      }
    });
  };
  const calculateFortnightEnd = (
    timeLimit: number,
    start: number,
    year: number,
  ) => {
    if (!timeLimit || !start || !year) {
      return;
    }

    let startSum = start;
    let yearSum = year;
    let limit = timeLimit - 1;

    do {
      if (startSum === 24) {
        startSum = 0;
        yearSum += 1;
      }
      startSum++;

      limit--;
    } while (limit > 0);

    setFieldsValue({
      sale: { fortnightEnd: startSum, fortnightYearEnd: yearSum },
    });
  };

  return (
    <Card loading={loadingQuery} title={title}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} sm={12} xs={24}>
            <Select<User>
              fieldName="sale.seller"
              form={form}
              formItemLabel="Promotor"
              id="sale-seller"
              initialValue={helpers.getOrUndefined(sale, 'seller.id')}
              renderOption={(user) => (
                <AntSelect.Option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/users/?seller=true"
            />
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Select<SaleType>
              fieldName="sale.saleType"
              form={form}
              formItemLabel="Tipo de Venta"
              id="sale-sale-type"
              initialValue={helpers.getOrUndefined(sale, 'type.id')}
              renderOption={(saleType) => (
                <AntSelect.Option key={saleType.id} value={saleType.id}>
                  {saleType.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/sales/types/"
            />
          </Col>

          <Col md={6} sm={12} xs={24}>
            <SelectSearch<Customer>
              fieldName="sale.customer"
              form={form}
              formItemLabel="Cliente"
              id="sale-customer"
              initialValue={helpers.getOrUndefined(sale, 'customer.id')}
              renderOption={(customer) => (
                <AntSelect.Option key={customer.id} value={customer.id}>
                  {customer.firstName} {customer.lastName}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/customers/"
            />
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Form.Item label="Fecha de Pedido">
              {getFieldDecorator('sale.dateInvoice', {
                initialValue: helpers.getOrUndefined(sale, 'dateInvoice'),
                rules: [rules.requiredRule()],
              })(<DatePicker style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Plazo">
              {getFieldDecorator('sale.timeLimit', {
                initialValue: helpers.getOrUndefined(sale, 'timeLimit'),
                rules: [rules.requiredRule()],
              })(
                <InputNumber
                  max={1000}
                  min={1}
                  onChange={(value) => {
                    const start = getFieldValue('sale.fortnightStart');
                    const timeLimit = Number(value);
                    const year = getFieldValue('sale.fortnightYearStart');
                    if (timeLimit) {
                      setTimeLimit(timeLimit);
                    }
                    if (timeLimit && start && year) {
                      calculateFortnightEnd(timeLimit, start, year);
                    }
                  }}
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Semana">
              {getFieldDecorator('sale.week', {
                initialValue: helpers.getOrUndefined(sale, 'week'),
                rules: [rules.requiredRule()],
              })(
                <InputNumber
                  max={54}
                  min={1}
                  placeholder="Semana"
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Año">
              {getFieldDecorator('sale.year', {
                initialValue: helpers.getOrUndefined(sale, 'year'),
                rules: [rules.requiredRule()],
              })(
                <InputNumber
                  max={new Date().getFullYear() + 20}
                  min={new Date().getFullYear() - 20}
                  placeholder="Año"
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={6} sm={12} xs={24}>
            <Form.Item label="Quincena inicio">
              {getFieldDecorator('sale.fortnightStart', {
                initialValue: helpers.getOrUndefined(sale, 'fortnightStart'),
                rules: [rules.requiredRule()],
              })(
                <InputNumber
                  max={24}
                  min={1}
                  onChange={(value) => {
                    const start = Number(value);
                    const timeLimit = getFieldValue('sale.timeLimit');
                    const year = getFieldValue('sale.fortnightYearStart');

                    if (timeLimit && start && Number(year) > 1990) {
                      calculateFortnightEnd(timeLimit, start, year);
                    }
                  }}
                  placeholder="Quincena"
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Form.Item label="Año">
              {getFieldDecorator('sale.fortnightYearStart', {
                initialValue: helpers.getOrUndefined(
                  sale,
                  'fortnightYearStart',
                ),
                rules: [rules.requiredRule()],
              })(
                <InputNumber
                  max={new Date().getFullYear() + 20}
                  min={new Date().getFullYear() - 10}
                  onChange={(value) => {
                    const start = getFieldValue('sale.fortnightStart');
                    const timeLimit = getFieldValue('sale.timeLimit');
                    const year = Number(value);

                    if (timeLimit && start && year > 1990) {
                      calculateFortnightEnd(timeLimit, start, year);
                    }
                  }}
                  placeholder="Año"
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Form.Item label="Quincena final">
              {getFieldDecorator('sale.fortnightEnd', {
                initialValue: helpers.getOrUndefined(sale, 'fortnightEnd'),
                rules: [rules.requiredRule()],
              })(<InputNumber style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Form.Item label="Año">
              {getFieldDecorator('sale.fortnightYearEnd', {
                initialValue: helpers.getOrUndefined(sale, 'fortnightYearEnd'),
                rules: [rules.requiredRule()],
              })(<InputNumber style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Divider orientation="left">Articulos</Divider>

          <Col span={24}>
            <SelectSearch<Item>
              fieldName="sale.items"
              form={form}
              formItemLabel="Articulos"
              id="sale-items"
              queryParams={{
                fortnight: debouncedTimeLimit,
              }}
              renderOption={(item) => (
                <AntSelect.Option key={item.id} value={item.id}>
                  [{item.code}] {item.description} | {item.serie} |{' '}
                  {item?.itemType?.name} | {item?.brand?.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              selectProps={{
                disabled: !Boolean(debouncedTimeLimit),
                labelInValue: true,
                mode: 'multiple',
              }}
              url="/items/"
            />
          </Col>

          <Col span={24}>
            {getFieldValue('sale.items') &&
              getFieldValue('sale.items').map(
                (item: { key: string; label: string[] }, index: number) => (
                  <Row key={index}>
                    <Form.Item
                      label={index === 0 && ''}
                      style={{ display: 'none' }}
                    >
                      {getFieldDecorator(`sale.itemDetail[${item.key}].item`, {
                        initialValue: item.key,
                      })(<Input disabled />)}
                    </Form.Item>

                    <Col md={4} sm={12} xs={24}>
                      <Form.Item label={index === 0 && 'Cantidad'}>
                        {getFieldDecorator(
                          `sale.itemDetail[${item.key}].quantity`,
                          { rules: [rules.requiredRule()] },
                        )(<InputNumber min={1} style={{ width: '100%' }} />)}
                      </Form.Item>
                    </Col>

                    <Col md={12} sm={12} xs={24}>
                      <Form.Item label={index === 0 && 'Articulo'}>
                        <Input disabled value={item.label.join('')} />
                      </Form.Item>
                    </Col>

                    <Col md={4} sm={12} xs={24}>
                      <ItemPriceFortnightInput
                        form={form}
                        fortnight={getFieldValue('sale.timeLimit')}
                        id={`sale.itemDetail[${item.key}]`}
                        itemId={item.key}
                        showLabel={index === 0}
                      />
                    </Col>

                    <Col md={4} sm={12} xs={24}>
                      <Form.Item label={index === 0 && 'Precio Total'}>
                        <InputNumber
                          disabled={true}
                          value={
                            getFieldValue(
                              `sale.itemDetail[${item.key}].quantity`,
                            ) &&
                            getFieldValue(
                              `sale.itemDetail[${item.key}].real`,
                            ) &&
                            Number(
                              getFieldValue(
                                `sale.itemDetail[${item.key}].quantity`,
                              ),
                            ) *
                              Number(
                                getFieldValue(
                                  `sale.itemDetail[${item.key}].real`,
                                ),
                              )
                          }
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>

                    {/*<Col md={13} sm={12} xs={24}>*/}
                    {/*  <Form.Item label={index === 0 && 'Comentarios'}>*/}
                    {/*    {getFieldDecorator(*/}
                    {/*      `sale.itemDetail[${item.key}].comments`,*/}
                    {/*    )(<Input />)}*/}
                    {/*  </Form.Item>*/}
                    {/*</Col>*/}
                  </Row>
                ),
              )}
          </Col>

          <Col span={24} style={{ textAlign: 'right' }}>
            <Typography.Title level={4}>
              Venta Total:{' '}
              {getFieldValue('sale.items')?.reduce((prev: any, curr: any) => {
                const itemDetail = getFieldValue(
                  `sale.itemDetail[${curr.key}]`,
                );

                const quantity = Number(itemDetail?.quantity);
                const real = Number(itemDetail?.real);

                if (quantity && real) {
                  prev += quantity * real;
                }

                return prev;
              }, 0)}
            </Typography.Title>
          </Col>
        </Row>

        <Row>
          <Divider orientation="left">Refinanciamientos</Divider>
          <Col span={24}>
            <Row>
              <Col span={16}>
                <Select<Sale>
                  fieldName="sale.refinancing"
                  form={form}
                  formItemLabel="Ventas"
                  id="sale-customer-sales"
                  initialValue={helpers.getOrUndefined(sale, 'type.id')}
                  queryParams={{
                    customer: getFieldValue('sale.customer'),
                  }}
                  renderOption={(option) => (
                    <AntSelect.Option key={option.id} value={option.id}>
                      {option.folio}
                    </AntSelect.Option>
                  )}
                  selectProps={{
                    disabled: !Boolean(getFieldValue('sale.customer')),
                    labelInValue: true,
                    mode: 'multiple',
                  }}
                  url="/sales/refinancing/"
                />
              </Col>
            </Row>
          </Col>

          {getFieldValue('sale.refinancing')?.map(
            (sale: { key: string; label: string | number }, index: number) => (
              <Col key={index} span={6}>
                <Form.Item style={{ display: 'none' }}>
                  {getFieldDecorator(`sale.refinancingTo[${index}].id`, {
                    initialValue: sale.key,
                  })(<Input disabled={true} style={{ width: '30%' }} />)}
                </Form.Item>

                <Form.Item>
                  <Input.Group compact>
                    <Input
                      disabled={true}
                      style={{ width: '30%' }}
                      value={sale.label}
                    />
                    {getFieldDecorator(`sale.refinancingTo[${index}].value`, {
                      rules: [rules.requiredRule()],
                    })(<InputNumber precision={2} style={{ width: '70%' }} />)}
                  </Input.Group>
                </Form.Item>
              </Col>
            ),
          )}
        </Row>

        <FileForm form={form} id="sale" initialValue={[]} />

        <SubmitButtons
          loading={loadingMutation}
          onCancel={() => history.push('/sales')}
        />
      </Form>
    </Card>
  );
};

const SaleForm = Form.create<SaleFormProps>()(_SaleForm);

export { SaleForm };
export default SaleForm;
