import React from 'react';

import { Card, Col, Divider, Form, Input, Select as AntSelect } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';

import { Addresses, Row, Select, SubmitButtons } from '../../components';
import { Branch, Customer, CustomerContractType } from '../../models';
import { helpers, rules } from '../../utils';

interface CustomerFormProps extends FormComponentProps {
  customer?: Partial<Customer>;
  loadingQuery: boolean;
  loadingMutation: boolean;
  onSubmit: (data: { customer: Customer }) => void;
  title: string;
}

export const _CustomerForm: React.FC<CustomerFormProps> = (props) => {
  const { customer, form, loadingQuery, loadingMutation, onSubmit } = props;
  const { title } = props;
  const { getFieldDecorator, validateFields } = form;

  const history = useHistory();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFields((errors, values) => {
      if (!errors) {
        onSubmit(values);
      }
    });
  };

  return (
    <Card loading={loadingQuery} title={title}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col span={24}>
            <Divider orientation="left">Información General</Divider>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={8}>
                <Form.Item label="Identificador Unico">
                  {getFieldDecorator('customer.unique', {
                    initialValue: helpers.getOrUndefined(customer, 'unique'),
                    rules: [rules.maxRule(100), rules.requiredRule()],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Nombre(s)">
              {getFieldDecorator('customer.firstName', {
                initialValue: helpers.getOrUndefined(customer, 'firstName'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Apellidos">
              {getFieldDecorator('customer.lastName', {
                initialValue: helpers.getOrUndefined(customer, 'lastName'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="RFC">
              {getFieldDecorator('customer.rfc', {
                initialValue: helpers.getOrUndefined(customer, 'rfc'),
                rules: [rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Select<Branch>
              fieldName="customer.branch"
              form={form}
              formItemLabel="Sucursal"
              id="customer-branch"
              initialValue={helpers.getOrUndefined(customer, 'branch.id')}
              renderOption={(branch) => (
                <AntSelect.Option key={branch.id} value={branch.id}>
                  {branch.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/branches/"
            />
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Codigo de Empleado">
              {getFieldDecorator('customer.code', {
                initialValue: helpers.getOrUndefined(customer, 'code'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Select<CustomerContractType>
              fieldName="customer.contractType"
              form={form}
              formItemLabel="Sección"
              id="customer-contractType"
              initialValue={helpers.getOrUndefined(customer, 'contractType.id')}
              renderOption={(contractType) => (
                <AntSelect.Option key={contractType.id} value={contractType.id}>
                  {contractType.name}
                </AntSelect.Option>
              )}
              url="/customers/contract-types/"
            />
          </Col>

          <Col span={24}>
            <Divider orientation="left">Información De Contacto</Divider>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Correo">
              {getFieldDecorator('customer.email', {
                initialValue: helpers.getOrUndefined(customer, 'email'),
                rules: [rules.emailRule(), rules.maxRule(50)],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Telefono">
              {getFieldDecorator('customer.phoneNumber', {
                initialValue: helpers.getOrUndefined(customer, 'phoneNumber'),
                rules: [rules.maxRule(20), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        {customer && customer.id && (
          <Addresses
            id="customers"
            url={`/customers/${customer.id}/addresses/`}
          />
        )}

        <SubmitButtons
          loading={loadingMutation}
          onCancel={() => history.push('/customers')}
        />
      </Form>
    </Card>
  );
};

export const CustomerForm = Form.create<CustomerFormProps>()(_CustomerForm);
