export const GET_CUSTOMER_BALANCE = 'GET_CUSTOMER_BALANCE';

export const getCustomerBalance = (id: string) => ({
  meta: {
    asPromise: true,
  },
  request: {
    method: 'GET',
    url: `/customers/${id}/balances/`,
  },
  type: GET_CUSTOMER_BALANCE,
});
