import { Installment } from '../../models';

export const UPDATE_INSTALLMENT = 'UPDATE_INSTALLMENT';

export const updateInstallment = (
  id: string,
  paymentId: string,
  data: Installment,
) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: data,
    method: 'POST',
    url: `/payments/${paymentId}/installments/?installment_id=${id}`,
  },
  type: UPDATE_INSTALLMENT,
});
