import { ItemFamily } from '../../../models';

export const ADD_ITEM_FAMILY = 'ADD_ITEM_FAMILY';

export const addItemFamily = (itemFamily: ItemFamily) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: itemFamily,
    method: 'POST',
    url: '/items/families/',
  },
  type: ADD_ITEM_FAMILY,
});
