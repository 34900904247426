import { User } from '../../models';

export const ADD_USER = 'ADD_USER';

export const addUser = (user: User) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: user,
    method: 'POST',
    url: '/users/',
  },
  type: ADD_USER,
});
