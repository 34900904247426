import { ItemType } from '../../../models';

export const UPDATE_ITEM_TYPE = 'UPDATE_ITEM_TYPE';

export const updateItemType = (id: string, itemType: ItemType) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: itemType,
    method: 'PUT',
    url: `/items/types/${id}/`,
  },
  type: UPDATE_ITEM_TYPE,
});
