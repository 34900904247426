import { lazy } from 'react';

import { pushToMenus, pushToRoutes } from './utils';

import { MenuRoute, RouterRoute, User } from '../models';

export default function (
  user: User,
  menus: MenuRoute[],
  routes: RouterRoute[],
) {
  pushToRoutes(
    routes,
    '/maintenance/add',
    lazy(() => import('../containers/MaintenanceAdd')),
    true,
  );

  pushToMenus(menus, { path: '/maintenance', title: 'Mantenimientos' });
  pushToRoutes(
    routes,
    '/maintenance',
    lazy(() => import('../containers/Maintenance')),
    true,
  );

  pushToMenus(menus, { path: '/stock', title: 'Stock' });
  pushToRoutes(
    routes,
    '/stock',
    lazy(() => import('../containers/SellerStock')),
    true,
  );

  pushToMenus(menus, { path: '/items', title: 'Articulos' });
  pushToRoutes(
    routes,
    '/items',
    lazy(() => import('../containers/SellerItems')),
    true,
  );

  pushToMenus(menus, { path: '/request', title: 'Pedidos' });
  pushToRoutes(
    routes,
    '/request',
    lazy(() => import('../containers/SellerRequests')),
    true,
  );
}
