import { CustomerContractType } from '../../../models';

export const UPDATE_CUSTOMER_CONTRACT_TYPE = 'UPDATE_CUSTOMER_CONTRACT_TYPE';

export const updateCustomerContractType = (
  id: string,
  customerContractType: CustomerContractType,
) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: customerContractType,
    method: 'PUT',
    url: `/customers/contract-types/${id}/`,
  },
  type: UPDATE_CUSTOMER_CONTRACT_TYPE,
});
