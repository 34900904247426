import { lazy } from 'react';

import { pushToMenus, pushToRoutes } from './utils';

import { MenuRoute, RouterRoute, User } from '../models';
import { perm } from '../utils';

export default function (
  user: User,
  menus: MenuRoute[],
  routes: RouterRoute[],
) {
  if (perm.hasPermission(user, perm.CAN_ADD_MAINTENANCE)) {
    pushToRoutes(
      routes,
      '/maintenance/add',
      lazy(() => import('../containers/MaintenanceAdd')),
      true,
    );
  }

  if (perm.hasPermission(user, perm.CAN_VIEW_MAINTENANCE)) {
    pushToMenus(menus, { path: '/maintenance', title: 'Mantenimientos' });
    pushToRoutes(
      routes,
      '/maintenance',
      lazy(() => import('../containers/Maintenance')),
      true,
    );
  }
}
