import React from 'react';

import { Card, Col, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';

import { Addresses, Row, SubmitButtons } from '../../components';
import { Vendor } from '../../models';
import { helpers, rules } from '../../utils';

interface VendorFormProps extends FormComponentProps {
  loadingQuery: boolean;
  loadingMutation: boolean;
  onSubmit: (data: { vendor: Vendor }) => void;
  title: string;
  vendor?: Partial<Vendor>;
}

const _VendorForm: React.FC<VendorFormProps> = (props) => {
  const { form, loadingQuery, loadingMutation, onSubmit, vendor } = props;
  const { title } = props;
  const { getFieldDecorator, validateFields } = form;

  const history = useHistory();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFields((errors, values) => {
      if (!errors) {
        onSubmit(values);
      }
    });
  };

  return (
    <Card loading={loadingQuery} title={title}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Nombre">
              {getFieldDecorator('vendor.name', {
                initialValue: helpers.getOrUndefined(vendor, 'name'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Contacto">
              {getFieldDecorator('vendor.contact', {
                initialValue: helpers.getOrUndefined(vendor, 'contact'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Correo">
              {getFieldDecorator('vendor.email', {
                initialValue: helpers.getOrUndefined(vendor, 'email'),
                rules: [
                  rules.emailRule(),
                  rules.maxRule(50),
                  rules.requiredRule(),
                ],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Telefono">
              {getFieldDecorator('vendor.phoneNumber', {
                initialValue: helpers.getOrUndefined(vendor, 'phoneNumber'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="RFC">
              {getFieldDecorator('vendor.rfc', {
                initialValue: helpers.getOrUndefined(vendor, 'rfc'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Sitio Web">
              {getFieldDecorator('vendor.website', {
                initialValue: helpers.getOrUndefined(vendor, 'website'),
                rules: [rules.maxRule(50)],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        {vendor && vendor.id && (
          <Addresses id="vendors" url={`/vendors/${vendor.id}/addresses/`} />
        )}

        <SubmitButtons
          loading={loadingMutation}
          onCancel={() => history.push('/settings/vendors')}
        />
      </Form>
    </Card>
  );
};

const VendorForm = Form.create<VendorFormProps>()(_VendorForm);

export { VendorForm };
export default VendorForm;
