import React, { useState } from 'react';

import { useQuery } from '@redux-requests/react';
import { Form, Icon, Select, Spin } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { LabeledValue, SelectProps } from 'antd/es/select';
import { useDispatch } from 'react-redux';
import { useDebounce, useDeepCompareEffect } from 'react-use';

import { GET_SELECT_SEARCH, getSelectSearch } from '../../actions';

interface SelectSearchProps<TOption> extends FormComponentProps {
  extraDataSource?: TOption[];
  fieldName: string;
  formItemLabel: string | React.ReactNode;
  initialValue?: string | Record<string, any> | LabeledValue;
  id: string;
  queryParams?: Record<string, any>;
  renderOption: (option: TOption) => React.ReactNode;
  rules?: Record<string, any>[];
  selectProps?: SelectProps<any>;
  url: string;
}

export function SelectSearch<TOption>(props: SelectSearchProps<TOption>) {
  const { extraDataSource, fieldName, form, formItemLabel } = props;
  const { id, initialValue, queryParams, renderOption, rules } = props;
  const { selectProps, url } = props;

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [searchValueDebounce, setSearchValueDebounce] = useState('');

  useDebounce(
    () => {
      setSearchValueDebounce(searchValue);
    },
    1000,
    [searchValue],
  );

  const { data, loading } = useQuery({
    defaultData: [],
    type: `${GET_SELECT_SEARCH}_${id.toUpperCase()}`,
  });

  useDeepCompareEffect(() => {
    if (searchValueDebounce !== '' && searchValueDebounce.length >= 3) {
      dispatch(
        getSelectSearch(id, url, {
          ...queryParams,
          search: searchValueDebounce,
        }),
      );
    }
  }, [id, dispatch, queryParams, searchValueDebounce, url]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Form.Item label={formItemLabel}>
      {form.getFieldDecorator(fieldName, {
        initialValue,
        rules,
      })(
        <Select
          {...selectProps}
          defaultActiveFirstOption={false}
          filterOption={false}
          notFoundContent={loading ? <Spin size="small" /> : null}
          onSearch={handleSearch}
          suffixIcon={<Icon type="search" />}
          showSearch
        >
          {data &&
            data
              .concat(extraDataSource || [])
              .map((option: TOption) => renderOption(option))}
        </Select>,
      )}
    </Form.Item>
  );
}
