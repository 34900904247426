import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';

import { ASSOCIATE_SALE, associateSale } from '../../actions';
import { ToolbarActions } from '../../components';
import { Sale } from '../../models';
import { rules } from '../../utils';

interface Props extends FormComponentProps {
  onClose: (refresh: boolean) => void;
  sale: Sale | null;
  visible: boolean;
}

const _SaleAssociateForm: React.FC<Props> = (props) => {
  const { form, onClose, sale, visible } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading } = useMutation({ type: ASSOCIATE_SALE });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (!errors && sale) {
        dispatch(associateSale(sale.id, { folio: values.sale.folio.trim() }))
          // @ts-ignore
          .then((response) => {
            !response?.error && onClose(true);
          })
          .catch(() => null);
      }
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose(false)}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={loading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={350}
    >
      <Form>
        <Form.Item label="Folio Unico">
          {getFieldDecorator('sale.folio', {
            rules: [rules.requiredRule()],
          })(<Input />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const SaleAssociateForm = Form.create<Props>()(_SaleAssociateForm);
