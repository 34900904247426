import { MaintenanceType } from '../../../models';

export const ADD_MAINTENANCE_TYPE = 'ADD_MAINTENANCE_TYPE';

export const addMaintenanceType = (maintenanceType: MaintenanceType) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: maintenanceType,
    method: 'POST',
    url: '/maintenance/types/',
  },
  type: ADD_MAINTENANCE_TYPE,
});
