import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Form, Input, Modal, Select as AntSelect } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';

import { ADD_CITY, addCity } from '../../actions';
import { Select, ToolbarActions } from '../../components';
import { Colony, Country, State } from '../../models';
import { rules } from '../../utils';

interface Props extends FormComponentProps {
  city: Colony | null;
  onClose: (refresh?: boolean) => void;
  visible: boolean;
}

const _ColonyForm: React.FC<Props> = (props) => {
  const { city, form, onClose, visible } = props;
  const { getFieldDecorator, getFieldValue, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading: addLoading } = useMutation({ type: ADD_CITY });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }

      const func = !city ? addCity : () => null;
      // @ts-ignore
      dispatch(func(values))
        // @ts-ignore
        .then((response) => !response.error && onClose(true))
        .catch(() => null);
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose()}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={addLoading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={370}
    >
      <Form>
        <Select<Country>
          fieldName={'country'}
          form={form}
          formItemLabel="Pais"
          id="city-country"
          renderOption={(country) => (
            <AntSelect.Option key={country.id} value={country.id}>
              {country.name}
            </AntSelect.Option>
          )}
          rules={[rules.requiredRule()]}
          url="/countries/"
        />

        <Select<State>
          fieldName={'state'}
          form={form}
          formItemLabel="Estado"
          id="city-state"
          queryParams={{
            countryId: getFieldValue('country'),
          }}
          renderOption={(state) => (
            <AntSelect.Option key={state.id} value={state.id}>
              {state.name}
            </AntSelect.Option>
          )}
          rules={[rules.requiredRule()]}
          selectProps={{
            disabled: !Boolean(getFieldValue('country')),
          }}
          url="/states/"
        />

        <Form.Item label="Ciudad">
          {getFieldDecorator('name', {
            rules: [rules.rangeRule(1, 50), rules.requiredRule()],
          })(<Input />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const CityForm = Form.create<Props>()(_ColonyForm);
