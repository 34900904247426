import { ItemBrand } from '../../../models';

export const ADD_ITEM_BRAND = 'ADD_ITEM_BRAND';

export const addItemBrand = (itemBrand: ItemBrand) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: itemBrand,
    method: 'POST',
    url: '/items/brands/',
  },
  type: ADD_ITEM_BRAND,
});
