import React, { useContext, useState } from 'react';

import { Button, Tabs } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ColumnProps } from 'antd/lib/table';
import { TabPaneProps } from 'antd/lib/tabs';
import { useDispatch } from 'react-redux';

import { CatalogForm } from '../CatalogForm';
import { DropDownActions } from '../DropDownActions';
import { Table } from '../Table';

import { getTable } from '../../actions';
import { UserContext } from '../../context';
import { perm } from '../../utils';

interface CatalogTabProps extends TabPaneProps {
  addConfig: {
    fn: (model: any) => void;
    type: string;
  };
  customForm?: (form: WrappedFormUtils, model: any) => React.ReactElement;
  id: string;
  tableConfig: {
    columns?: ColumnProps<any>[];
    id: string;
    url: string;
  };
  updateConfig: {
    fn: (id: string, model: any) => void;
    type: string;
  };
}

export const CatalogTab: React.FC<CatalogTabProps> = (props) => {
  const { addConfig, customForm, id, tableConfig, updateConfig } = props;

  const user = useContext(UserContext);

  const dispatch = useDispatch();
  const [modalProperties, setModalProperties] = useState<{
    model: any | null;
    visible: boolean;
  }>({
    model: null,
    visible: false,
  });

  const handleMenuClick = (key: string, item: any) => {
    if (key === 'edit') {
      setModalProperties({ model: item, visible: true });
    }
  };

  const columnFileds = tableConfig.columns || [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Nombre',
    },
  ];

  const columns: ColumnProps<any>[] = [
    ...columnFileds,
    {
      className: 'text-center',
      fixed: 'right',
      key: 'actions',
      render: (text, item) => {
        const menuOptions = [];

        if (perm.hasPermission(user, perm.CAN_EDIT_CATALOGS)) {
          menuOptions.push({ key: 'edit', name: 'Editar' });
        }

        return (
          <DropDownActions
            menuOptions={menuOptions}
            onMenuClick={({ key }) => handleMenuClick(key, item)}
          />
        );
      },
      title: 'Acciones',
      width: 80,
    },
  ];

  const actions = () => {
    if (perm.hasPermission(user, perm.CAN_ADD_CATALOGS)) {
      return [
        <Button
          htmlType="button"
          icon="plus"
          key="add"
          onClick={() => setModalProperties({ model: null, visible: true })}
        />,
      ];
    }
    return [];
  };

  return (
    <Tabs.TabPane {...props}>
      <CatalogForm
        addConfig={addConfig}
        customForm={customForm}
        id={id}
        model={modalProperties.model}
        onClose={() => {
          setModalProperties({ model: null, visible: false });
          dispatch(getTable(tableConfig.id, tableConfig.url));
        }}
        visible={modalProperties.visible}
        updateConfig={updateConfig}
      />

      <Table
        actions={actions()}
        columns={columns}
        id={tableConfig.id}
        url={tableConfig.url}
      />
    </Tabs.TabPane>
  );
};
