import React from 'react';

import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale-provider/es_ES';
import moment from 'moment';
import 'moment/locale/es';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import { Root } from './containers/Root';
import configureStore from './store/configureStore';

moment.locale('es');

const store = configureStore();

render(
  <ConfigProvider locale={esES}>
    <Provider store={store}>
      <Router>
        <Root />
      </Router>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root'),
);
