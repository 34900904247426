import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Form, Modal, Select as AntSelect } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';

import { ADD_ROUTE_SELLER, addRouteSeller } from '../../actions';
import { UPDATE_ROUTE_SELLER, updateRouteSeller } from '../../actions';
import { Select, ToolbarActions } from '../../components';
import { Route, RouteSeller, User, Vehicle } from '../../models';
import { helpers, rules } from '../../utils';

interface RouteSellerFormProps extends FormComponentProps {
  onClose: (refresh: boolean) => void;
  routeSeller: RouteSeller | null;
  visible: boolean;
}

const _RouteSellerForm: React.FC<RouteSellerFormProps> = (props) => {
  const { form, onClose, routeSeller, visible } = props;
  const { validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading: addLoading } = useMutation({ type: ADD_ROUTE_SELLER });
  const { loading: updateLoading } = useMutation({ type: UPDATE_ROUTE_SELLER });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }

      const func = !routeSeller ? addRouteSeller : updateRouteSeller;
      const data = !routeSeller
        ? [values.routeSeller]
        : [routeSeller.id, values.routeSeller];

      // @ts-ignore
      dispatch(func(...data)).then(
        (response: any) => response.data && onClose(true),
      );
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose(false)}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={addLoading || updateLoading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={370}
    >
      <Form>
        <Select<Route>
          fieldName="routeSeller.route"
          form={form}
          formItemLabel="Ruta"
          id="route-seller_routes"
          initialValue={helpers.getOrUndefined(routeSeller, 'route.id')}
          renderOption={(route) => (
            <AntSelect.Option key={route.id} value={route.id}>
              {route.name}
            </AntSelect.Option>
          )}
          rules={[rules.requiredRule()]}
          url="/routes/"
        />

        <Select<User>
          fieldName="routeSeller.seller"
          form={form}
          formItemLabel="Promotor"
          id="route-seller_seller"
          initialValue={helpers.getOrUndefined(routeSeller, 'user.id')}
          renderOption={(user) => (
            <AntSelect.Option key={user.id} value={user.id}>
              {user.firstName} {user.lastName}
            </AntSelect.Option>
          )}
          rules={[rules.requiredRule()]}
          url="/users/?seller=true"
        />

        <Select<Vehicle>
          fieldName="routeSeller.vehicle"
          form={form}
          formItemLabel="Vehiculo"
          id="route-seller_vehicle"
          initialValue={helpers.getOrUndefined(routeSeller, 'vehicle.id')}
          renderOption={(vehicle) => (
            <AntSelect.Option key={vehicle.id} value={vehicle.id}>
              {vehicle.name}
            </AntSelect.Option>
          )}
          rules={[rules.requiredRule()]}
          url="/vehicles/"
        />
      </Form>
    </Modal>
  );
};

export const RouteSellerForm = Form.create<RouteSellerFormProps>()(
  _RouteSellerForm,
);
