import { UserJob } from '../../../models';

export const UPDATE_USER_JOB = 'UPDATE_USER_JOB';

export const updateUserJob = (id: string, job: UserJob) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: job,
    method: 'PUT',
    url: `/users/jobs/${id}/`,
  },
  type: UPDATE_USER_JOB,
});
