import { lazy } from 'react';

import { pushToMenus, pushToRoutes } from './utils';

import { MenuRoute, RouterRoute, User } from '../models';
import { perm } from '../utils';

export default function (
  user: User,
  menus: MenuRoute[],
  routes: RouterRoute[],
) {
  if (perm.hasPermission(user, perm.CAN_VIEW_PAYMENTS)) {
    pushToMenus(menus, {
      path: '/payments',
      title: 'Abonos',
    });
    pushToRoutes(
      routes,
      '/payments',
      lazy(() => import('../containers/Payments')),
      true,
    );
    pushToRoutes(
      routes,
      '/payments/:paymentId/installments',
      lazy(() => import('../containers/Installments')),
      true,
    );
  }
}
