import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Col, Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';

import { CLOSE_SALE, closeSale } from '../../actions';
import { Row, ToolbarActions } from '../../components';
import { Sale } from '../../models';
import { rules } from '../../utils';

interface Props extends FormComponentProps {
  onClose: (refresh: boolean) => void;
  sale: Sale | null;
  type: string;
  visible: boolean;
}

const _SaleCloseForm: React.FC<Props> = (props) => {
  const { form, onClose, sale, type, visible } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading } = useMutation({ type: CLOSE_SALE });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (!errors && sale) {
        dispatch(closeSale(type, sale.id, { ...values.sale }))
          // @ts-ignore
          .then((response) => {
            !response.error && onClose(true);
          });
      }
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose(false)}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={loading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={570}
    >
      <Form>
        <Row>
          <Col span={24}>
            <Form.Item label="Commentarios">
              {getFieldDecorator('sale.comments', {
                rules: [rules.requiredRule()],
              })(<Input.TextArea rows={3} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const SaleCloseForm = Form.create<Props>()(_SaleCloseForm);
