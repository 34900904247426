import { VehicleType } from '../../../models';

export const ADD_VEHICLE_TYPE = 'ADD_VEHICLE_TYPE';

export const addVehicleType = (vehicleType: VehicleType) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: vehicleType,
    method: 'POST',
    url: '/vehicles/types/',
  },
  type: ADD_VEHICLE_TYPE,
});
