import { Route } from '../../models';

export const UPDATE_ROUTE = 'UPDATE_ROUTE';

export const updateRoute = (id: string, route: Route) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: route,
    method: 'PUT',
    url: `/routes/${id}/`,
  },
  type: UPDATE_ROUTE,
});
