import { SaleType } from '../../../models';

export const ADD_SALE_TYPE = 'ADD_SALE_TYPE';

export const addSaleType = (saleType: SaleType) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: saleType,
    method: 'POST',
    url: '/sales/types/',
  },
  type: ADD_SALE_TYPE,
});
