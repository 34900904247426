import { Item } from '../../models';

export const ADD_ITEM = 'ADD_ITEM';

export const addItem = (item: Item) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: item,
    method: 'POST',
    url: '/items/',
  },
  type: ADD_ITEM,
});
