import { Installment } from '../../models';

export const ADD_INSTALLMENT = 'ADD_INSTALLMENT';

export const addInstallment = (
  paymentId: string,
  data: Installment,
) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: data,
    method: 'POST',
    url: `/payments/${paymentId}/installments/`,
  },
  type: ADD_INSTALLMENT,
});
