import { UserGender } from '../../../models';

export const ADD_USER_GENDER = 'ADD_USER_GENDER';

export const addUserGender = (gender: UserGender) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: gender,
    method: 'POST',
    url: '/users/genders/',
  },
  type: ADD_USER_GENDER,
});
