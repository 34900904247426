export const LOGIN = 'LOGIN';

export const login = (data: { email: string; password: string }) => ({
  meta: { asPromise: true },
  request: {
    data,
    method: 'POST',
    url: '/login/',
  },
  type: LOGIN,
});
