import React from 'react';

import { Col, Input } from 'antd';

interface TableSearchInputProps {
  loading: boolean;
  onSearch: (value: string) => void;
}

export const TableSearchInput: React.FC<TableSearchInputProps> = (props) => (
  <React.Fragment>
    <Col
      md={{ span: 7, order: 1 }}
      sm={{ span: 8, order: 1 }}
      style={{ marginBottom: '15px' }}
      xs={{ span: 24, order: 2 }}
    >
      <Input.Search
        disabled={props.loading}
        enterButton
        onSearch={(value) => props.onSearch(value)}
        placeholder="Buscar..."
      />
    </Col>
  </React.Fragment>
);
