import { Address } from '../../models';

export const ADD_ADDRESS = 'ADD_ADDRESS';

export const addAddress = (url: string, address: Address) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: address,
    method: 'POST',
    url: url,
  },
  type: ADD_ADDRESS,
});
