import { PaymentPending } from '../../models';

export const ADD_PAYMENT_BY_PENDING = 'ADD_PAYMENT_BY_PENDING';

export const addPaymentByPending = (
  paymentPending: PaymentPending,
  data: { folio: string, sale: string },
) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: data,
    method: 'POST',
    url: `/payments/pending/${paymentPending.id}/associate/`,
  },
  type: ADD_PAYMENT_BY_PENDING,
});
