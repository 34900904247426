import { User } from '../../models';

export const UPDATE_USER = 'UPDATE_USER';

export const updateUser = (id: string, user: User) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: user,
    method: 'PUT',
    url: `/users/${id}/`,
  },
  type: UPDATE_USER,
});
