import React from 'react';

import { getQuerySelector } from '@redux-requests/core';
import { useMutation } from '@redux-requests/react';
import { Button, Form, Input, Modal, Select, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch, useSelector } from 'react-redux';

import { ADD_PAYMENT_BY_PENDING, addPaymentByPending } from '../../actions';
import { SelectSearch, ToolbarActions } from '../../components';
import { rules } from '../../utils';
import { PaymentPending, Sale } from '../../models';

interface Props extends FormComponentProps {
  paymentPending: PaymentPending | null;
  onClose: (refresh: boolean) => void;
  visible: boolean;
}

const _CreatePaymentModal: React.FC<Props> = (props) => {
  const { form, onClose, paymentPending, visible } = props;
  const { getFieldDecorator, getFieldValue, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading } = useMutation({ type: ADD_PAYMENT_BY_PENDING });
  const { data } = useSelector(
    getQuerySelector({
      type: `GET_SELECT_SEARCH_${'payment-pending-sale'.toUpperCase()}`,
    }),
  );

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (errors || !paymentPending) {
        return;
      }

      // @ts-ignore
      dispatch(addPaymentByPending(paymentPending, values))
        // @ts-ignore
        .then((response) => response.data && onClose(true))
        .catch(() => null);
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose(false)}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={loading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={370}
    >
      {paymentPending && (
        <Form>
          <Form.Item
            label="Folio"
            extra="Si el folio existe se agregara el abono, de lo contrario
              se creara el primer abono"
          >
            {getFieldDecorator('folio', {
              initialValue: paymentPending.folio,
              rules: [rules.requiredRule()],
            })(<Input />)}
          </Form.Item>

          <SelectSearch<Sale>
            fieldName="sale"
            form={form}
            formItemLabel="Venta"
            id="payment-pending-sale"
            queryParams={{
              identifier: paymentPending.identifier
            }}
            renderOption={(option) => (
              <Select.Option key={option.id} value={option.id}>
                [{option.folio}] {option.customer.firstName}{' '}
                {option.customer.lastName}
              </Select.Option>
            )}
            rules={[rules.requiredRule()]}
            url="/sales/"
          />

          {getFieldValue('sale') && data && (
            <Typography>
              <Typography.Paragraph strong={true}>
                Folios actuales
              </Typography.Paragraph>
              <Typography.Paragraph>
                {data
                  // @ts-ignore
                  .find((sale) => sale.id === getFieldValue('sale'))
                  // @ts-ignore
                  .payments.map((payment) => payment.folio)
                  // @ts-ignore
                  .map((folio) => (
                    <Typography.Paragraph key={folio} style={{ margin: 0 }}>
                      {folio}
                    </Typography.Paragraph>
                  ))}
              </Typography.Paragraph>
            </Typography>
          )}
        </Form>
      )}
    </Modal>
  );
};

export const CreatePaymentModal = Form.create<Props>()(_CreatePaymentModal);
