import { Route } from '../../models';

export const ADD_ROUTE = 'ADD_ROUTE';

export const addRoute = (route: Route) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: route,
    method: 'POST',
    url: '/routes/',
  },
  type: ADD_ROUTE,
});
