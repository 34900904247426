import React from 'react';

import { Button, Col, Tooltip } from 'antd';

interface TableReloadButtonProps {
  actions?: Array<React.ReactNode>;
  loading: boolean;
  onReload: () => void;
}

export const TableReloadButton: React.FC<TableReloadButtonProps> = ({
  actions = [],
  loading,
  onReload,
}) => (
  <React.Fragment>
    <Col
      className="mb-15"
      md={{ span: 17, order: 2 }}
      sm={{ span: 16, order: 2 }}
      style={{ textAlign: 'right' }}
      xs={{ span: 24, order: 1 }}
    >
      <Button.Group>
        {actions.map((action) => action)}

        <Tooltip title="Recargar">
          <Button
            htmlType="button"
            icon="reload"
            loading={loading}
            onClick={onReload}
          />
        </Tooltip>
      </Button.Group>
    </Col>
  </React.Fragment>
);
