import { ItemPrice } from '../../../models';

export const UPDATE_ITEM_PRICE = 'UPDATE_ITEM_PRICE';

export const updateItemPrice = (id: string, itemPrice: ItemPrice) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: itemPrice,
    method: 'PUT',
    url: `/items/prices/${id}/`,
  },
  type: UPDATE_ITEM_PRICE,
});
