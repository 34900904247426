import React from 'react';

import { Button, Col, Divider, Form, Icon, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import { Row } from '../Row';

import { rules } from '../../utils';

interface RouteFormProps extends FormComponentProps {
  form: WrappedFormUtils;
  id: string;
  initialValue: string[];
}

export const FileForm: React.FC<RouteFormProps> = (props) => {
  const { form, id, initialValue = [] } = props;
  const { getFieldDecorator, getFieldValue } = form;

  const mainKey = '__files';

  getFieldDecorator(mainKey, { initialValue: initialValue });
  const files = getFieldValue(mainKey);

  // @ts-ignore
  const remove = (k) => {
    const files = form.getFieldValue(`${id}.files`);
    form.setFieldsValue({
      // @ts-ignore
      [mainKey]: files.filter((key, index) => index !== k),
      // @ts-ignore
      'user.files': files.filter((key, index) => index !== k),
    });
  };

  const add = () => {
    const files = form.getFieldValue(mainKey);
    const nextFiles = files.concat('');
    form.setFieldsValue({
      [mainKey]: nextFiles,
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Divider orientation="left">Documentos</Divider>
      </Col>
      {
        // @ts-ignore
        files.map((file, index) => (
          <Col key={index} span={12}>
            <Form.Item label={''}>
              {getFieldDecorator(`${id}.files[${index}]`, {
                initialValue: file,
                rules: [rules.maxRule(200), rules.requiredRule()],
              })(
                <Input
                  addonAfter={
                    <Icon onClick={() => remove(index)} type="delete" />
                  }
                />,
              )}
            </Form.Item>
          </Col>
        ))
      }

      <Col span={24} style={{ textAlign: 'right' }}>
        <Form.Item>
          <Button type="dashed" onClick={add}>
            <Icon type="plus" /> Nueva URL
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};
