import { UserGroup } from '../../../models';

export const ADD_USER_GROUP = 'ADD_USER_GROUP';

export const addUserGroup = (group: UserGroup) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: group,
    method: 'POST',
    url: '/users/groups/',
  },
  type: ADD_USER_GROUP,
});
