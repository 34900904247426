import React, { useContext } from 'react';

import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { UserContext } from '../../context';
import { MenuRoute } from '../../models';
import { getConfig } from '../../routes';

interface Props {
  collapsed: boolean;
}

export const Sider: React.FC<Props> = (props) => {
  const { collapsed } = props;

  const user = useContext(UserContext);
  const location = useLocation();

  const menus: MenuRoute[] = getConfig(user).menus;

  const renderMenu = (menu: MenuRoute) => (
    <Menu.Item key={menu.path}>
      <Link to={menu.path}>{menu.title}</Link>
    </Menu.Item>
  );

  const renderSubMenu = (menu: MenuRoute) => (
    <Menu.SubMenu key={menu.path} title={menu.title}>
      {menu.subMenus && menu.subMenus.map((subMenu) => renderMenu(subMenu))}
    </Menu.SubMenu>
  );

  return (
    <Layout.Sider
      collapsed={collapsed}
      collapsedWidth={0}
      collapsible={true}
      trigger={null}
    >
      <Menu
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        theme="dark"
        style={{ lineHeight: '64px' }}
      >
        {menus.map((menu) => {
          if (menu.subMenus) {
            return renderSubMenu(menu);
          }
          return renderMenu(menu);
        })}
      </Menu>
    </Layout.Sider>
  );
};
