import { lazy } from 'react';

import { pushToMenus, pushToRoutes } from './utils';

import { MenuRoute, RouterRoute, User } from '../models';

export default function (
  user: User,
  menus: MenuRoute[],
  routes: RouterRoute[],
) {
  if (user?.isAdmin) {
    pushToMenus(
      menus,
      { path: '/settings/branches', title: 'Sucursales' },
      { path: '/settings', title: 'Configuracion' },
    );
    pushToRoutes(
      routes,
      '/settings/branches/add',
      lazy(() => import('../containers/Settings/Branches/BranchAdd')),
      true,
    );
    pushToRoutes(
      routes,
      '/settings/branches',
      lazy(() => import('../containers/Settings/Branches/Branches')),
      true,
    );
    pushToRoutes(
      routes,
      '/settings/branches/:id/update',
      lazy(() => import('../containers/Settings/Branches/BranchUpdate')),
      true,
    );
  }
}
