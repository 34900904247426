import React, { Suspense } from 'react';

import { Route, RouteProps } from 'react-router-dom';

import { Layout } from '../Layout';

import { RouterRoute } from '../../models';

interface PrivateRouteProps extends RouteProps {
  route: RouterRoute;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const { route } = props;
  const { component: Component, exact, key, path } = route;

  if (!Component) {
    return null;
  }

  return (
    <Layout>
      <Suspense fallback="Cargando">
        <Route exact={exact} key={key} path={path}>
          <Component />
        </Route>
      </Suspense>
    </Layout>
  );
};
