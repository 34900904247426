import React, { useEffect, useState } from 'react';

import { useQuery } from '@redux-requests/react';
import { Button, Card, Col, Checkbox, Form, Row, Input, Transfer } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { GET_USER_PERMISSIONS, getUserPermissions } from '../../actions';
import { UserGroup, UserPermission } from '../../models';
import { ToolbarActions } from '../ToolbarActions';
import { helpers, rules } from '../../utils';

interface Props extends FormComponentProps {
  loadingQuery: boolean;
  loadingMutation: boolean;
  onSubmit: (data: { userGroup: UserGroup }) => void;
  title: string;
  userGroup?: Partial<UserGroup>;
}

export const _UserGroupForm: React.FC<Props> = (props) => {
  const { form, loadingQuery, loadingMutation, onSubmit, title } = props;
  const { userGroup } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const history = useHistory();
  const { data: permissions, loading } = useQuery({
    defaultData: [],
    type: GET_USER_PERMISSIONS,
  });
  const [transferData, setTransferData] = useState<{
    selectedKeys: string[];
    targetKeys: string[];
  }>({
    selectedKeys: [],
    targetKeys: [],
  });

  useEffect(() => {
    if (userGroup?.permissions?.length) {
      setTransferData({
        selectedKeys: [],
        targetKeys: userGroup?.permissions?.map((permission) => permission.id),
      });
    }
    dispatch(getUserPermissions());
  }, [dispatch, userGroup]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }
      onSubmit({
        userGroup: {
          ...values.userGroup,
          isSeller: Boolean(values.userGroup?.isSeller),
          permissions: transferData.targetKeys.map((tk) => ({ id: tk })),
        },
      });
    });
  };

  return (
    <Card loading={loadingQuery || loading} title={title}>
      <Form>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Nombre">
              {getFieldDecorator(`userGroup.name`, {
                initialValue: helpers.getOrUndefined(userGroup, 'name'),
                rules: [rules.rangeRule(1, 40), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="¿Es Promotor?">
              {getFieldDecorator(`userGroup.isSeller`, {
                initialValue: helpers.getOrUndefined(userGroup, 'isSeller'),
                valuePropName: 'checked',
              })(<Checkbox>Si</Checkbox>)}
            </Form.Item>
          </Col>
        </Row>

        <Transfer
          dataSource={permissions?.map((permission: UserPermission) => ({
            ...permission,
            key: permission.id,
          }))}
          listStyle={{
            height: 300,
            width: 400,
          }}
          onChange={(nextTargetKeys, direction, moveKeys) => {
            setTransferData({ ...transferData, targetKeys: nextTargetKeys });
          }}
          onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
            setTransferData({
              ...transferData,
              selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
            });
          }}
          render={(permission) => ({
            label: <span>{permission.name}</span>,
            value: permission.id,
          })}
          selectedKeys={transferData.selectedKeys}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
          targetKeys={transferData.targetKeys}
        />

        <ToolbarActions
          extra={
            <Button onClick={() => history.push('/settings/users')}>
              Cancelar
            </Button>
          }
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={loadingMutation}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      </Form>
    </Card>
  );
};

export const UserGroupForm = Form.create<Props>()(_UserGroupForm);
