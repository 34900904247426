import { Item } from '../../models';

export const UPDATE_ITEM = 'UPDATE_ITEM';

export const updateItem = (id: string, item: Item) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: item,
    method: 'PUT',
    url: `/items/${id}/`,
  },
  type: UPDATE_ITEM,
});
