import React from 'react';

import { Sale } from '../../models';

interface Props {
  sale: Sale;
}

export const SaleStatus: React.FC<Props> = ({ sale }) => (
  <span>
    {sale.status === 'canceled' && 'Cancelado'}
    {sale.status === 'closed' && 'Cerrada'}
    {sale.status === 'closing' && 'Revision'}
    {sale.status === 'completed' && 'Completada'}
    {sale.status === 'pending' && 'Pendiente'}
    {sale.status === 'pending_cancel' && 'Pendiente Cancelar'}
    {sale.status === 'refinanced' && 'Refinanciado'}
  </span>
);
