import { lazy } from 'react';

import { pushToMenus, pushToRoutes } from './utils';

import { MenuRoute, RouterRoute, User } from '../models';
import { perm } from '../utils';

export default function (
  user: User,
  menus: MenuRoute[],
  routes: RouterRoute[],
) {
  if (perm.hasPermission(user, perm.CAN_ADD_CUSTOMERS)) {
    pushToRoutes(
      routes,
      '/customers/add',
      lazy(() => import('../containers/CustomerAdd')),
      true,
    );
  }

  pushToRoutes(
    routes,
    '/customers/:id/sales',
    lazy(() => import('../containers/CustomerSales')),
    true,
  );

  if (perm.hasPermission(user, perm.CAN_EDIT_CUSTOMERS)) {
    pushToRoutes(
      routes,
      '/customers/:id/update',
      lazy(() => import('../containers/CustomerUpdate')),
      true,
    );
  }

  if (perm.hasPermission(user, perm.CAN_VIEW_CUSTOMERS)) {
    pushToMenus(menus, { path: '/customers', title: 'Clientes' });
    pushToRoutes(
      routes,
      '/customers',
      lazy(() => import('../containers/Customers')),
      true,
    );
  }
}
