import React from 'react';

import { Address } from '../../models';
import { helpers } from '../../utils';

interface AddressFullNameProps {
  address: Address;
}

export const AddressFullName: React.FC<AddressFullNameProps> = ({
  address,
}) => (
  <div>
    <span>{helpers.getOrUndefined(address, 'street')}</span>&nbsp;
    <span>{helpers.getOrUndefined(address, 'streetNumber')}</span>&nbsp;
    <span>{helpers.getOrUndefined(address, 'apartmentNumber')}</span>&nbsp;
    <span>{helpers.getOrUndefined(address, 'postcode')}</span>&nbsp;
    <span>{helpers.getOrUndefined(address, 'colony.name')}</span>&nbsp;
    <span>{helpers.getOrUndefined(address, 'city.name')}</span>&nbsp;
    <span>{helpers.getOrUndefined(address, 'state.name')}</span>&nbsp;
    <span>{helpers.getOrUndefined(address, 'country.name')}</span>
  </div>
);
