import { lazy } from 'react';

import { pushToMenus, pushToRoutes } from './utils';

import { MenuRoute, RouterRoute, User } from '../models';
import { perm } from '../utils';

export default function (
  user: User,
  menus: MenuRoute[],
  routes: RouterRoute[],
) {
  if (
    perm.hasPermission(user, perm.CAN_VIEW_MADE_PURCHASES) ||
    perm.hasPermission(user, perm.CAN_VIEW_PENDING_PURCHASES)
  ) {
    pushToMenus(menus, {
      path: '/purchases',
      title: 'Compras',
    });
    pushToRoutes(
      routes,
      '/purchases',
      lazy(() => import('../containers/Purchases')),
      true,
    );
  }

  if (perm.hasPermission(user, perm.CAN_PURCHASE_ITEMS)) {
    pushToRoutes(
      routes,
      '/sales/:id/purchase',
      lazy(() => import('../containers/SalePurchase')),
      true,
    );
  }
}
