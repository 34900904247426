export const ADD_VEHICLE_KILOMETERS = 'ADD_VEHICLE_KILOMETERS';

export const addVehicleKilometers = (id: string, data: any) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: data,
    method: 'POST',
    url: `/vehicles/${id}/kilometers/`,
  },
  type: ADD_VEHICLE_KILOMETERS,
});
