import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';

import { CHANGE_USER_PASSWORD, changeUserPassword } from '../../actions';
import { ToolbarActions } from '../../components';
import { User } from '../../models';
import { rules } from '../../utils';

interface ChangePasswordFormProps extends FormComponentProps {
  onClose: () => void;
  user: User | null;
  visible: boolean;
}

const _ChangePasswordForm: React.FC<ChangePasswordFormProps> = (props) => {
  const { form, onClose, user, visible } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading } = useMutation({ type: CHANGE_USER_PASSWORD });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (errors || !user) {
        return;
      }
      dispatch(changeUserPassword(user.id, values.password))
        // @ts-ignore
        .then((response) => !response.error && onClose());
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={onClose}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={loading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={370}
    >
      <Form>
        <Form.Item label="Nueva contraseña">
          {getFieldDecorator('password', {
            rules: [rules.maxRule(50), rules.requiredRule()],
          })(<Input />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const ChangeUserPasswordForm = Form.create<ChangePasswordFormProps>()(
  _ChangePasswordForm,
);
