import { Colony } from '../../models';

export const ADD_COLONY = 'ADD_COLONY';

export const addColony = (colony: Colony) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: colony,
    method: 'POST',
    url: '/colonies/',
  },
  type: ADD_COLONY,
});
