import { Branch } from '../../models';

export const ADD_BRANCH = 'ADD_BRANCH';

export const addBranch = (branch: Branch) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: branch,
    method: 'POST',
    url: '/branches/',
  },
  type: ADD_BRANCH,
});
