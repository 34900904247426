import React from 'react';

import { Button } from 'antd';

import { ToolbarActions } from '../ToolbarActions';

interface StepsButtonsProps {
  cancelButton?: React.ReactNode;
  extra?: React.ReactNode;
  loading: boolean;
  onCancel: () => void;
}

export const SubmitButtons: React.FC<StepsButtonsProps> = (props) => {
  const { cancelButton, extra, loading, onCancel } = props;

  return (
    <ToolbarActions extra={extra}>
      {cancelButton !== undefined ? (
        cancelButton
      ) : (
        <Button disabled={loading} htmlType="button" onClick={onCancel}>
          Cancelar
        </Button>
      )}
      <Button loading={loading} htmlType="submit" type="primary">
        Guardar
      </Button>
    </ToolbarActions>
  );
};
