import React from 'react';

import { Col, Row } from 'antd';

import './ToolbarActions.css';

interface ToolbarActionsProps {
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  extra?: React.ReactNode;
}

export const ToolbarActions: React.FC<ToolbarActionsProps> = (props) => (
  <Row className="toolbar-container" style={props.containerStyle}>
    {props.extra && (
      <Col span={12}>
        <div className="toolbar-left">{props.extra}</div>
      </Col>
    )}
    <Col span={props.extra ? 12 : 24}>
      <div className="toolbar-right">{props.children}</div>
    </Col>
  </Row>
);
