import { UserGender } from '../../../models';

export const UPDATE_USER_GENDER = 'UPDATE_USER_GENDER';

export const updateUserGender = (id: string, gender: UserGender) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: gender,
    method: 'PUT',
    url: `/users/genders/${id}/`,
  },
  type: UPDATE_USER_GENDER,
});
