import { UserJob } from '../../../models';

export const ADD_USER_JOB = 'ADD_USER_JOB';

export const addUserJob = (job: UserJob) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: job,
    method: 'POST',
    url: '/users/jobs/',
  },
  type: ADD_USER_JOB,
});
