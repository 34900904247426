import { Purchase } from '../../models';

export const ADD_PURCHASE = 'ADD_PURCHASE';

export const addPurchase = (purchase: Purchase) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: purchase,
    method: 'POST',
    url: '/purchases/',
  },
  type: ADD_PURCHASE,
});
