import { CustomerContractType } from '../../../models';

export const ADD_CUSTOMER_CONTRACT_TYPE = 'ADD_CUSTOMER_CONTRACT_TYPE';

export const addCustomerContractType = (
  customerContractType: CustomerContractType,
) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: customerContractType,
    method: 'POST',
    url: '/customers/contract-types/',
  },
  type: ADD_CUSTOMER_CONTRACT_TYPE,
});
