import React from 'react';

import { Card, Col, Form, Input, Select as AntSelect } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';

import { Row, Select, SubmitButtons } from '../../components';
import { Brand, User, Vehicle, VehicleType } from '../../models';
import { helpers, rules } from '../../utils';

interface VehicleFormProps extends FormComponentProps {
  loadingQuery: boolean;
  loadingMutation: boolean;
  onSubmit: (data: { vehicle: Vehicle }) => void;
  title: string;
  vehicle?: Partial<Vehicle>;
}

const _VehicleForm: React.FC<VehicleFormProps> = (props) => {
  const { form, loadingQuery, loadingMutation, onSubmit, vehicle } = props;
  const { title } = props;
  const { getFieldDecorator, validateFields } = form;

  const history = useHistory();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFields((errors, values) => {
      if (!errors) {
        onSubmit(values);
      }
    });
  };

  return (
    <Card loading={loadingQuery} title={title}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Nombre">
              {getFieldDecorator('vehicle.name', {
                initialValue: helpers.getOrUndefined(vehicle, 'name'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Codigo">
              {getFieldDecorator('vehicle.code', {
                initialValue: helpers.getOrUndefined(vehicle, 'code'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Select<User>
              fieldName="vehicle.seller"
              form={form}
              formItemLabel="Promotor"
              id="sale-seller"
              initialValue={helpers.getOrUndefined(vehicle, 'seller.id')}
              renderOption={(user) => (
                <AntSelect.Option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/users/?seller=true"
            />
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Modelo">
              {getFieldDecorator('vehicle.model', {
                initialValue: helpers.getOrUndefined(vehicle, 'model'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Placa">
              {getFieldDecorator('vehicle.plate', {
                initialValue: helpers.getOrUndefined(vehicle, 'plate'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="No. Serie">
              {getFieldDecorator('vehicle.serie', {
                initialValue: helpers.getOrUndefined(vehicle, 'serie'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Select<VehicleType>
              fieldName="vehicle.vehicleType"
              form={form}
              formItemLabel="Tipo de Vehiculo"
              id="types"
              initialValue={helpers.getOrUndefined(vehicle, 'vehicleType.id')}
              renderOption={(vehicleType) => (
                <AntSelect.Option key={vehicleType.id} value={vehicleType.id}>
                  {vehicleType.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/vehicles/types/"
            />
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Select<Brand>
              fieldName="vehicle.vehicleBrand"
              form={form}
              formItemLabel="Marca"
              id="vehicle-brand"
              initialValue={helpers.getOrUndefined(vehicle, 'vehicleBrand.id')}
              renderOption={(brand) => (
                <AntSelect.Option key={brand.id} value={brand.id}>
                  {brand.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/vehicles/brands/"
            />
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Numero de motor">
              {getFieldDecorator('vehicle.motor', {
                initialValue: helpers.getOrUndefined(vehicle, 'motor'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Aseguradora">
              {getFieldDecorator('vehicle.insurance', {
                initialValue: helpers.getOrUndefined(vehicle, 'insurance'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Número de poliza">
              {getFieldDecorator('vehicle.policyNumber', {
                initialValue: helpers.getOrUndefined(vehicle, 'policyNumber'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <SubmitButtons
          loading={loadingMutation}
          onCancel={() => history.push('/settings/vehicles')}
        />
      </Form>
    </Card>
  );
};

const VehicleForm = Form.create<VehicleFormProps>()(_VehicleForm);

export { VehicleForm };
export default VehicleForm;
