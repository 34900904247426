export const CHANGE_SALE_FOLIO = 'CHANGE_SALE_FOLIO';

export const changeSaleFolio = (
  saleId: string,
  data: { action: string; payment: string, value: string },
) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: data,
    method: 'PUT',
    url: `/sales/${saleId}/folios/`,
  },
  type: CHANGE_SALE_FOLIO,
});
