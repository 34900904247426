import React from 'react';

import { Card, Col, Form, Input, Select as AntSelect } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';

import { Row, SelectSearch, SubmitButtons } from '../../components';
import { Item, ItemBrand, ItemFamily, UserGender } from '../../models';
import { helpers, rules } from '../../utils';

interface ItemFormProps extends FormComponentProps {
  item?: Partial<Item>;
  loadingQuery: boolean;
  loadingMutation: boolean;
  onSubmit: (data: { item: Item }) => void;
  title: string;
}

const _ItemForm: React.FC<ItemFormProps> = (props) => {
  const { item, form, loadingQuery, loadingMutation, onSubmit } = props;
  const { title } = props;
  const { getFieldDecorator, validateFields } = form;

  const history = useHistory();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFields((errors, values) => {
      if (!errors) {
        onSubmit(values);
      }
    });
  };

  return (
    <Card loading={loadingQuery} title={title}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Codigo">
              {getFieldDecorator('item.code', {
                initialValue: helpers.getOrUndefined(item, 'code'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Producto">
              {getFieldDecorator('item.description', {
                initialValue: helpers.getOrUndefined(item, 'description'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item label="Modelo">
              {getFieldDecorator('item.serie', {
                initialValue: helpers.getOrUndefined(item, 'serie'),
                rules: [rules.maxRule(50), rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={8} sm={12} xs={24}>
            <SelectSearch<UserGender>
              fieldName="item.itemType"
              form={form}
              formItemLabel="Tipo"
              id="item-item-type"
              initialValue={helpers.getOrUndefined(item, 'itemType.id')}
              renderOption={(itemType) => (
                <AntSelect.Option key={itemType.id} value={itemType.id}>
                  {itemType.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/items/types/"
            />
          </Col>

          <Col md={8} sm={12} xs={24}>
            <SelectSearch<ItemBrand>
              fieldName="item.brand"
              form={form}
              formItemLabel="Marca"
              id="item-brand"
              initialValue={helpers.getOrUndefined(item, 'brand.id')}
              renderOption={(brand) => (
                <AntSelect.Option key={brand.id} value={brand.id}>
                  {brand.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/items/brands/"
            />
          </Col>

          <Col md={8} sm={12} xs={24}>
            <SelectSearch<ItemFamily>
              fieldName="item.family"
              form={form}
              formItemLabel="Linea"
              id="item-family"
              initialValue={helpers.getOrUndefined(item, 'family.id')}
              renderOption={(family) => (
                <AntSelect.Option key={family.id} value={family.id}>
                  {family.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/items/families/"
            />
          </Col>

          <Col span={24}>
            <Form.Item label="Comentarios">
              {getFieldDecorator('item.comment', {
                initialValue: helpers.getOrUndefined(item, 'comment'),
              })(<Input.TextArea rows={3} />)}
            </Form.Item>
          </Col>
        </Row>

        <SubmitButtons
          loading={loadingMutation}
          onCancel={() => history.push('/settings/items')}
        />
      </Form>
    </Card>
  );
};

export const ItemForm = Form.create<ItemFormProps>()(_ItemForm);
