import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal } from 'antd';
import { Select as AntSelect } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { ADD_PURCHASE, addPurchase } from '../../actions';
import { FileForm, Row, Select, ToolbarActions } from '../../components';
import { SaleItem, Vendor } from '../../models';
import { rules } from '../../utils';

interface Props extends FormComponentProps {
  onClose: (refresh: boolean) => void;
  saleItem: SaleItem | null;
  visible: boolean;
}

const _SalePurchaseItemForm: React.FC<Props> = (props) => {
  const { form, onClose, saleItem, visible } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading } = useMutation({ type: ADD_PURCHASE });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (!errors && saleItem) {
        dispatch(
          addPurchase({
            ...values.purchase,
            purchaseDate: moment(values.purchase.purchaseDate).format(
              'YYYY-MM-DD',
            ),
            saleItem: saleItem.id,
          }),
        )
          // @ts-ignore
          .then((response) => {
            if (!response.error) {
              onClose(true);
            }
          })
          .catch(() => null);
      }
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose(false)}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={loading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={570}
    >
      <Form>
        <Row>
          <Col span={8}>
            <Form.Item label="Factura">
              {getFieldDecorator('purchase.folio', {
                rules: [rules.requiredRule()],
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Select<Vendor>
              fieldName="purchase.vendor"
              form={form}
              formItemLabel="Proveedor"
              id="purchase-vendor"
              renderOption={(vendor) => (
                <AntSelect.Option key={vendor.id} value={vendor.id}>
                  {vendor.name}
                </AntSelect.Option>
              )}
              rules={[rules.requiredRule()]}
              url="/vendors/"
            />
          </Col>

          <Col span={8}>
            <Form.Item label="Precio">
              {getFieldDecorator('purchase.price', {
                rules: [rules.requiredRule()],
              })(<InputNumber min={1} style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Fecha de Compra">
              {getFieldDecorator('purchase.purchaseDate', {
                rules: [rules.requiredRule()],
              })(<DatePicker style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Semana">
              {getFieldDecorator('purchase.week', {
                rules: [rules.requiredRule()],
              })(
                <InputNumber
                  max={54}
                  min={1}
                  placeholder="Semana"
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Año">
              {getFieldDecorator('purchase.year', {
                rules: [rules.requiredRule()],
              })(
                <InputNumber
                  max={new Date().getFullYear() + 20}
                  min={new Date().getFullYear()}
                  placeholder="Año"
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Comentarios">
              {getFieldDecorator('purchase.comments')(
                <Input.TextArea rows={3} />,
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <FileForm form={form} id="purchase" initialValue={[]} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const SalePurchaseItemForm = Form.create<Props>()(_SalePurchaseItemForm);
