import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Form, InputNumber, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';

import { CANCEL_SALE, cancelSale } from '../../actions';
import { ToolbarActions } from '../../components';
import { Sale } from '../../models';
import { rules } from '../../utils';

interface Props extends FormComponentProps {
  onClose: (refresh: boolean) => void;
  sale: Sale | null;
  visible: boolean;
}

const _SaleCancelForm: React.FC<Props> = (props) => {
  const { form, onClose, sale, visible } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading } = useMutation({ type: CANCEL_SALE });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (!errors && sale) {
        dispatch(cancelSale(sale.id, values.sale))
          // @ts-ignore
          .then(() => {
            onClose(true);
          })
          .catch(() => null);
      }
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose(false)}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={loading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={350}
    >
      <Form>
        <Form.Item label="Codigo de cancelacion">
          {getFieldDecorator('sale.cancelCode', {
            rules: [rules.requiredRule()],
          })(
            <InputNumber max={999999} min={100000} style={{ width: '100%' }} />,
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const SaleCancelForm = Form.create<Props>()(_SaleCancelForm);
