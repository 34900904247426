import { Vendor } from '../../models';

export const ADD_VENDOR = 'ADD_VENDOR';

export const addVendor = (vendor: Vendor) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: vendor,
    method: 'POST',
    url: '/vendors/',
  },
  type: ADD_VENDOR,
});
