import React, { useState } from 'react';

import { Button, Col, Divider } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { AddressFullName } from '../AddressFullName';
import { AddressModalForm } from '../AddressModalForm';
import { DropDownActions } from '../DropDownActions';
import { Row } from '../Row';
import { Table } from '../Table';
import { Address } from '../../models';

interface AddressFormProps {
  id: string;
  url: string;
}

interface AddressesColumns extends ColumnProps<Address> {}

export const Addresses: React.FC<AddressFormProps> = (props) => {
  const { id, url } = props;

  const [address, setAddress] = useState<Address | null>(null);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const actions = [
    <Button
      htmlType="button"
      icon="plus"
      key="add"
      onClick={() => setModalVisibility(true)}
    />,
  ];

  const columns: AddressesColumns[] = [
    {
      key: 'address',
      render: (text, address) => <AddressFullName address={address} />,
      title: 'Dirección',
    },
    {
      className: 'text-center',
      fixed: 'right',
      key: 'actions',
      render: (text, address) => (
        <DropDownActions
          menuOptions={[{ key: 'edit', name: 'Editar' }]}
          onMenuClick={({ key }) => {
            if (key === 'edit') {
              setAddress(address);
              setModalVisibility(true);
            }
          }}
        />
      ),
      title: 'Acciones',
      width: 80,
    },
  ];

  return (
    <React.Fragment>
      <AddressModalForm
        address={address}
        id={id}
        onClose={() => {
          setAddress(null);
          setModalVisibility(false);
        }}
        url={url}
        visible={modalVisibility}
      />

      <Row>
        <Divider orientation="left">Direcciones</Divider>
        <Col span={24}>
          <Table<Address>
            actions={actions}
            columns={columns}
            id={id}
            url={url}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};
