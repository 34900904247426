import { Brand } from '../../../models';

export const ADD_VEHICLE_BRAND = 'ADD_VEHICLE_BRAND';

export const addVehicleBrand = (brand: Brand) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: brand,
    method: 'POST',
    url: '/vehicles/brands/',
  },
  type: ADD_VEHICLE_BRAND,
});
