export const CANCEL_SALE = 'CANCEL_SALE';

export const cancelSale = (saleId: string, data: any) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: data,
    method: 'POST',
    url: `/sales/${saleId}/cancel/`,
  },
  type: CANCEL_SALE,
});
