import { Brand } from '../../../models';

export const UPDATE_VEHICLE_BRAND = 'UPDATE_VEHICLE_BRAND';

export const updateVehicleBrand = (id: string, brand: Brand) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: brand,
    method: 'PUT',
    url: `/vehicles/brands/${id}/`,
  },
  type: UPDATE_VEHICLE_BRAND,
});
