import { User } from '../models';
import { find } from 'lodash';

export const hasPermission = (user: User | undefined, permission: string) => {
  if (!user) {
    return false;
  }

  if (user.isAdmin) {
    return true;
  }
  const groupPermissions = user?.group?.permissions;
  return Boolean(find(groupPermissions, ['value', permission]));
};

// Branches
export const CAN_ADD_BRANCHES = 'can_add_branches';
export const CAN_EDIT_BRANCHES = 'can_edit_branches';
export const CAN_VIEW_BRANCHES = 'can_view_branches';

// Catalogs
export const CAN_ADD_CATALOGS = 'can_add_catalogs';
export const CAN_EDIT_CATALOGS = 'can_edit_catalogs';
export const CAN_VIEW_CATALOGS = 'can_view_catalogs';

// Certify
export const CAN_CERTIFY = 'can_certify';

// Customers
export const CAN_ADD_CUSTOMERS = 'can_add_customers';
export const CAN_EDIT_CUSTOMERS = 'can_edit_customers';
export const CAN_VIEW_CUSTOMERS = 'can_view_customers';
export const CAN_VIEW_CUSTOMERS_BALANCE = 'can_view_customers_balance';

// Items
export const CAN_ADD_ITEMS = 'can_add_items';
export const CAN_ADD_ITEMS_PRICES = 'can_add_items_prices';
export const CAN_EDIT_ITEMS = 'can_edit_items';
export const CAN_EDIT_ITEMS_PRICES = 'can_edit_items_prices';
export const CAN_VIEW_ITEMS = 'can_view_items';
export const CAN_VIEW_ITEMS_PRICES = 'can_view_items_prices';

// Maintenance
export const CAN_ADD_MAINTENANCE = 'can_add_maintenance';
export const CAN_VIEW_MAINTENANCE = 'can_view_maintenance';

// Purchases
export const CAN_VIEW_PAYMENTS = 'can_view_payments';

// Purchases
export const CAN_PURCHASE_ITEMS = 'can_purchase_items';
export const CAN_VIEW_MADE_PURCHASES = 'can_view_made_purchases';
export const CAN_VIEW_PENDING_PURCHASES = 'can_view_pending_purchases';
export const CAN_VIEW_PURCHASES = 'can_view_purchases';

// Reports
export const CAN_VIEW_REPORTS = 'can_view_reports';

// Requests
export const CAN_VIEW_REQUESTS = 'can_view_requests';
export const CAN_CHANGE_REQUEST_STATUS = 'can_change_request_status';

// Sales
export const CAN_ADD_SALES = 'can_add_sales';
export const CAN_ASSOCIATE_SALES = 'can_associate_sales';
export const CAN_CANCEL_SALES = 'can_cancel_sales';
export const CAN_VIEW_SALES = 'can_view_sales';

// User
export const CAN_ADD_USERS = 'can_add_users';
export const CAN_ADD_USERS_GROUPS = 'can_add_users_groups';
export const CAN_CHANGE_USERS_PASSWORDS = 'can_change_users_passwords';
export const CAN_EDIT_USERS = 'can_edit_users';
export const CAN_EDIT_USERS_GROUPS = 'can_edit_users_groups';
export const CAN_VIEW_USERS = 'can_view_users';
export const CAN_VIEW_USERS_GROUPS = 'can_view_users_groups';

// Vehicles
export const CAN_ADD_VEHICLES = 'can_add_vehicles';
export const CAN_EDIT_VEHICLES = 'can_edit_vehicles';
export const CAN_VIEW_VEHICLES = 'can_view_vehicles';

// Vendors
export const CAN_ADD_VENDORS = 'can_add_vendors';
export const CAN_EDIT_VENDORS = 'can_edit_vendors';
export const CAN_VIEW_VENDORS = 'can_view_vendors';

// Warehouses
export const CAN_VIEW_WAREHOUSES = 'can_view_warehouses';
