import { Sale } from '../../models';

export const ADD_SALE = 'ADD_SALE';

export const addSale = (sale: Sale) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: sale,
    method: 'POST',
    url: '/sales/',
  },
  type: ADD_SALE,
});
