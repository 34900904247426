import { VehicleType } from '../../../models';

export const UPDATE_VEHICLE_TYPE = 'UPDATE_VEHICLE_TYPE';

export const updateVehicleType = (id: string, vehicleType: VehicleType) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: vehicleType,
    method: 'PUT',
    url: `/vehicles/types/${id}/`,
  },
  type: UPDATE_VEHICLE_TYPE,
});
