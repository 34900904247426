import { Vehicle } from '../../models';

export const ADD_VEHICLE = 'ADD_VEHICLE';

export const addVehicle = (vehicle: Vehicle) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: vehicle,
    method: 'POST',
    url: '/vehicles/',
  },
  type: ADD_VEHICLE,
});
