import { MaintenanceType } from '../../../models';

export const UPDATE_MAINTENANCE_TYPE = 'UPDATE_MAINTENANCE_TYPE';

export const updateMaintenanceType = (
  id: string,
  maintenanceType: MaintenanceType,
) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: maintenanceType,
    method: 'PUT',
    url: `/maintenance/types/${id}/`,
  },
  type: UPDATE_MAINTENANCE_TYPE,
});
