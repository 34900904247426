import React, { useEffect } from 'react';

import { Form, Input, Skeleton } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';
import { useQuery } from '@redux-requests/react';

import { GET_ITEM_PRICE_BY_FORTNIGHT } from '../../actions';
import { getItemPriceByFortnight } from '../../actions';
import { rules } from '../../utils';

interface ItemPriceFortnightInputProps extends FormComponentProps {
  fortnight: string;
  id: string;
  itemId: string;
  showLabel: boolean;
}

export const ItemPriceFortnightInput: React.FC<ItemPriceFortnightInputProps> = (
  props,
) => {
  const { fortnight, showLabel, id, itemId } = props;

  const dispatch = useDispatch();
  const { data, loading } = useQuery({
    defaultData: {},
    type: `${GET_ITEM_PRICE_BY_FORTNIGHT}_${itemId}_${fortnight}`,
  });

  useEffect(() => {
    dispatch(getItemPriceByFortnight(itemId, fortnight));
  }, [dispatch, fortnight, itemId]);

  if (loading) {
    return <Skeleton active loading />;
  }

  return (
    <React.Fragment>
      <Form.Item label={showLabel && ''} style={{ display: 'none' }}>
        {props.form.getFieldDecorator(`${id}.price`, {
          initialValue: data.id,
          rules: [rules.requiredRule()],
        })(<Input />)}
      </Form.Item>

      <Form.Item label={showLabel && 'Precio Lista Unitario'}>
        {props.form.getFieldDecorator(`${id}.real`, {
          initialValue: data.price,
          rules: [rules.requiredRule()],
        })(<Input />)}
      </Form.Item>
    </React.Fragment>
  );
};
