import { lazy } from 'react';

import { pushToMenus, pushToRoutes } from './utils';

import { MenuRoute, RouterRoute, User } from '../models';
import { perm } from '../utils';

export default function (
  user: User,
  menus: MenuRoute[],
  routes: RouterRoute[],
) {
  if (perm.hasPermission(user, perm.CAN_ADD_USERS)) {
    pushToRoutes(
      routes,
      '/settings/users/add',
      lazy(() => import('../containers/Settings/Users/UsersTab/UserAdd')),
      true,
    );
  }

  if (perm.hasPermission(user, perm.CAN_ADD_USERS_GROUPS)) {
    pushToRoutes(
      routes,
      '/settings/users/groups/add',
      lazy(() =>
        import('../containers/Settings/Users/UserGroupsTab/UserGroupAdd'),
      ),
      true,
    );
  }

  if (perm.hasPermission(user, perm.CAN_EDIT_USERS)) {
    pushToRoutes(
      routes,
      '/settings/users/:id/update',
      lazy(() => import('../containers/Settings/Users/UsersTab/UserUpdate')),
      true,
    );
  }

  if (perm.hasPermission(user, perm.CAN_EDIT_USERS_GROUPS)) {
    pushToRoutes(
      routes,
      '/settings/users/groups/:id/update',
      lazy(() =>
        import('../containers/Settings/Users/UserGroupsTab/UserGroupUpdate'),
      ),
      true,
    );
  }

  if (
    perm.hasPermission(user, perm.CAN_VIEW_USERS) ||
    perm.hasPermission(user, perm.CAN_VIEW_USERS_GROUPS)
  ) {
    pushToMenus(
      menus,
      { path: '/settings/users', title: 'Usuarios' },
      { path: '/settings', title: 'Configuracion' },
    );
    pushToRoutes(
      routes,
      '/settings/users',
      lazy(() => import('../containers/Settings/Users/Users')),
      true,
    );
  }
}
