import { decamelizeKeys } from 'humps';
import { stringify } from 'query-string';

export const GET_ITEMS = 'GET_ITEMS';

export const getItems = (queryParams: Record<string, any> | undefined) => ({
  request: {
    method: 'GET',
    url: !queryParams
      ? '/items/'
      : `/items/?${stringify(decamelizeKeys(queryParams))}`,
  },
  type: GET_ITEMS,
});
