import { UserMaritalStatus } from '../../../models';

export const ADD_USER_MARITAL_STATUS = 'ADD_USER_MARITAL_STATUS';

export const addUserMaritalStatus = (maritalStatus: UserMaritalStatus) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: maritalStatus,
    method: 'POST',
    url: '/users/marital-status/',
  },
  type: ADD_USER_MARITAL_STATUS,
});
