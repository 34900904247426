import React from 'react';

import { useMutation } from '@redux-requests/react';
import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';

import { ADD_INSTALLMENT, addInstallment } from '../../actions';
import { UPDATE_INSTALLMENT, updateInstallment } from '../../actions';
import { ToolbarActions } from '../../components';
import { Installment, Payment } from '../../models';
import { rules } from '../../utils';

interface Props extends FormComponentProps {
  installment: Installment | null;
  isUpdate?: boolean;
  onClose: (refresh: boolean) => void;
  payment: Payment | null;
  visible: boolean;
}

const _ModalForm: React.FC<Props> = (props) => {
  const { form, installment, isUpdate, onClose, payment, visible } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const dispatch = useDispatch();
  const { loading: addLoading } = useMutation({ type: ADD_INSTALLMENT });
  const { loading: updateLoading } = useMutation({ type: UPDATE_INSTALLMENT });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFieldsAndScroll((errors, values) => {
      if (errors || !payment) {
        return;
      }

      if (isUpdate && !!installment) {
        dispatch(
          updateInstallment(installment.id, payment.id, {
            payment: payment.id,
            ...values,
          }),
        )
          // @ts-ignore
          .then((response) => !response.error && onClose(true))
          .catch(() => null);
      }

      if (!isUpdate && !installment) {
        dispatch(addInstallment(payment.id, { payment: payment.id, ...values }))
          // @ts-ignore
          .then((response) => !response.error && onClose(true))
          .catch(() => null);
      }
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={() => onClose(false)}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={addLoading || updateLoading}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      title={isUpdate ? 'Modificar abono' : 'Agregar abonos'}
      visible={visible}
      width={400}
    >
      <Form>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item label="Abono">
              {getFieldDecorator('amount', {
                rules: [rules.requiredRule()],
              })(<InputNumber precision={2} style={{ width: '100%' }} />)}
            </Form.Item>
            <Col span={24}>
              <Form.Item label="Estatus">
                {getFieldDecorator('status', {
                  rules: [rules.requiredRule()],
                })(<InputNumber style={{ width: '100%' }} />)}
              </Form.Item>
            </Col>
          </Col>
          {!isUpdate && (
            <React.Fragment>
              <Col span={12}>
                <Form.Item label="Quincena">
                  {getFieldDecorator('fortnight', {
                    rules: [rules.requiredRule()],
                  })(
                    <InputNumber max={24} min={1} style={{ width: '100%' }} />,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Año">
                  {getFieldDecorator('year', {
                    rules: [rules.requiredRule()],
                  })(
                    <InputNumber
                      max={2100}
                      min={1990}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Comentarios">
                  {getFieldDecorator('comments', {
                    rules: [rules.requiredRule()],
                  })(<Input.TextArea rows={3} />)}
                </Form.Item>
              </Col>
            </React.Fragment>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export const ModalForm = Form.create<Props>()(_ModalForm);
