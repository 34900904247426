import React from 'react';

import { Col, Row as AntRow, Typography } from 'antd';
import { RowProps as AntRowProps } from 'antd/lib/grid';

import './Row.css';

interface RowProps extends AntRowProps {
  title?: string;
}

export const Row: React.FC<RowProps> = ({ children, title, ...rowProps }) => (
  <AntRow gutter={16} {...rowProps}>
    {title && title.length && (
      <Col span={18}>
        <div className="row-title">
          <span className="row-title-content">
            {' '}
            <Typography.Text strong underline>
              {title}
            </Typography.Text>
          </span>
        </div>
      </Col>
    )}
    {children}
  </AntRow>
);
