export const CANCEL_REQUEST = 'CANCEL_REQUEST';

export const cancelRequest = (id: string) => ({
  meta: {
    asPromise: true,
  },
  request: {
    method: 'POST',
    url: `/requests/${id}/cancel/`,
  },
  type: CANCEL_REQUEST,
});
