import React from 'react';

import { Button, Dropdown, Icon, Menu } from 'antd';
import { ClickParam } from 'antd/lib/menu';

interface MenuOption {
  key: string;
  name: string;
}
interface DropDownActionsProps {
  menuOptions: MenuOption[];
  onMenuClick: (param: ClickParam) => void;
}

export const DropDownActions: React.FC<DropDownActionsProps> = (props) => {
  const { menuOptions, onMenuClick } = props;

  if (!menuOptions.length) {
    return null;
  }

  return (
    <Dropdown
      overlay={
        <Menu onClick={onMenuClick}>
          {menuOptions.map((item) => (
            <Menu.Item key={item.key}>{item.name}</Menu.Item>
          ))}
        </Menu>
      }
    >
      {props.children ? (
        props.children
      ) : (
        <Button htmlType="button">
          <div>
            <Icon style={{ marginRight: 2 }} type="bars" />
          </div>
        </Button>
      )}
    </Dropdown>
  );
};

DropDownActions.defaultProps = {
  menuOptions: [],
};
