export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';

export const changeUserPassword = (id: string, password: string) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: { password },
    method: 'POST',
    url: `/users/${id}/change-password/`,
  },
  type: CHANGE_USER_PASSWORD,
});
