import { ItemBrand } from '../../../models';

export const UPDATE_ITEM_BRAND = 'UPDATE_ITEM_BRAND';

export const updateItemBrand = (id: string, itemBrand: ItemBrand) => ({
  meta: {
    asPromise: true,
  },
  request: {
    data: itemBrand,
    method: 'PUT',
    url: `/items/brands/${id}/`,
  },
  type: UPDATE_ITEM_BRAND,
});
