import { notification } from 'antd';
import axios from 'axios';
import humps from 'humps';

import { jwt } from './index';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
  },

  transformResponse: [
    // @ts-ignore
    ...axios.defaults.transformResponse,
    (response) => {
      if (response.status === 'error') {
        if (response.code !== 401) {
          notification.error({
            message: 'Error',
          });
        }
      }
      return humps.camelizeKeys(response.data);
    },
  ],
  transformRequest: [
    (data: any, headers: any) => {
      const token = jwt.getToken();
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      if (data instanceof FormData) {
        return data;
      }
      return humps.decamelizeKeys(data);
    },
    // @ts-ignore
    ...axios.defaults.transformRequest,
  ],
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject({ ...error });
  },
);

export default axiosInstance;
