import React from 'react';

import { Form, Modal, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useDispatch } from 'react-redux';
import { useMutation } from '@redux-requests/react';

import { AddressForm } from '../AddressForm';
import { ToolbarActions } from '../ToolbarActions';
import { ADD_ADDRESS, addAddress, getTable } from '../../actions';
import { UPDATE_ADDRESS, updateAddress } from '../../actions';
import { Address } from '../../models';

interface AddressModalFormProps extends FormComponentProps {
  address: Address | null;
  id: string;
  onClose: () => void;
  url: string;
  visible: boolean;
}

const _AddressModalForm: React.FC<AddressModalFormProps> = (props) => {
  const { address: currentAddress, form, id, onClose, url, visible } = props;
  const { validateFields } = form;

  const dispatch = useDispatch();
  const { loading: loadingAdd } = useMutation({
    type: ADD_ADDRESS,
  });
  const { loading: loadingUpdate } = useMutation({
    type: UPDATE_ADDRESS,
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    validateFields((errors, values) => {
      if (!errors) {
        if (currentAddress) {
          dispatch(
            updateAddress(url, {
              id: currentAddress.id,
              ...values.modal.address,
            }),
          )
            // @ts-ignore
            .then((response: any) => {
              if (response.data) {
                dispatch(getTable(id, url));
                onClose();
              }
            });
        } else {
          dispatch(addAddress(url, values.modal.address))
            // @ts-ignore
            .then((response: any) => {
              if (response.data) {
                dispatch(getTable(id, url));
                onClose();
              }
            });
        }
      }
    });
  };

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={
        <ToolbarActions
          extra={<Button onClick={onClose}>Cancelar</Button>}
          containerStyle={{ marginTop: 0 }}
        >
          <Button
            loading={loadingAdd || loadingUpdate}
            onClick={(event) => handleSubmit(event)}
            type="primary"
          >
            Guardar
          </Button>
        </ToolbarActions>
      }
      maskClosable={false}
      visible={visible}
      width={650}
    >
      <Form>
        <AddressForm address={currentAddress} form={form} id="modal" />
      </Form>
    </Modal>
  );
};

export const AddressModalForm = Form.create<AddressModalFormProps>()(
  _AddressModalForm,
);
